import { BrowserRouter, Routes, Route } from "react-router-dom";
import { lazy, Suspense } from "react";
import "./fontawesome";
import { OpenAssessment } from "./components/assessment/open";
import { AttemptAssessment } from "./components/assessment/attempt";
import { AttemptResult } from "./components/assessment/attempt_result";
import { NewStudent } from "./components/students/new";
import { ManageStudent } from "./components/students/manage";
import Loading from "./components/layout/loading";
import RequireAuth from "./components/utils/RequireAuth";
import { FlashAlert } from "./components/utils/alerts";
import { Topic } from "./components/study/topics";
import Error404 from "./components/errors/404";
import CustomRoutes from "./routes/customization";

const SignIn = lazy(() => import("./components/auth/login"));
const SignUp = lazy(() => import("./components/auth/signup"));
const Verify = lazy(() => import("./components/auth/verify"));
const PageContainer = lazy(() => import("./components/layout/container"));
// const RequireAuth = lazy(() => import("./components/utils/RequireAuth"));
const Home = lazy(() => import("./components/Home/index"));
const Profile = lazy(() => import("./components/user/profile"));
const Settings = lazy(() => import("./components/user/settings"));
const ChoosePractice = lazy(() => import("./components/study/practice"));
//students
const Students = lazy(() => import("./components/students/list"));
const StudentDetail = lazy(() => import("./components/students/detail"));
//assessment 
const AssessmentList = lazy(() => import("./components/assessment/list"));
const AssessmentListSubmit = lazy(() => import("./components/assessment/list-submit"));
const AssessmentCreate = lazy(() => import("./components/assessment/create"));
const AssessmentEdit = lazy(() => import("./components/assessment/edit"));
//class
const ClassList = lazy(() => import("./components/study/classes/list"));
const ClassRead = lazy(() => import("./components/study/classes/read"));
const ClassCreate = lazy(() => import("./components/study/classes/create"));
const ClassEdit = lazy(() => import("./components/study/classes/edit"));
//Notification
const NotificationList = lazy(() => import("./components/notification/list"));
const NotificationRead = lazy(() => import("./components/notification/read"));

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <FlashAlert />
      <BrowserRouter>
        <Routes>
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/verify/:uname" element={<Verify />} />
          <Route path="/" element={<RequireAuth><PageContainer /></RequireAuth>}>
            <Route index element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/settings" >
              <Route index element={<Settings />} />
              <Route path="profile" element={<Settings />} />
              <Route path="password" element={<Settings tab="password" />} />
              <Route path="notifications" element={<Settings tab="notifications" />} />
            </Route>
            <Route path="/students">
              <Route index element={<Students />} />
              <Route path=":classID" element={<Students />} />
              <Route path="detail/:id" element={<StudentDetail />} />
              <Route path="new/:classID" index element={<NewStudent />} />
              <Route path="manage/:id" index element={<ManageStudent />} />
            </Route>
            <Route path="/assessment">
              <Route index element={<AssessmentList />} />
              <Route path=":classID" element={<AssessmentList />} />
              <Route path="submits/:assessmentID" element={<AssessmentListSubmit />} />
              <Route path="create" element={<AssessmentCreate />} />
              <Route path="edit/:id" element={<AssessmentEdit />} />
              <Route path="open/:id" element={<OpenAssessment />} />
              <Route path="attempt/:id" element={<AttemptAssessment />} />
              <Route path="attempt/result/:id" element={<AttemptResult />} />
            </Route>
            <Route path="/class" >
              <Route index element={<ClassList />} />
              <Route path=":id" element={<ClassRead />} />
              <Route path="create" element={<ClassCreate />} />
              <Route path="edit/:id" element={<ClassEdit />} />
            </Route>
            <Route path="/academy" >
              <Route index element={<ClassList />} />
              <Route path=":id" element={<ClassRead />} />
              <Route path="create" element={<ClassCreate />} />
              <Route path="edit/:id" element={<ClassEdit />} />
            </Route>
            <Route path="/topic" >
              <Route path=":tag" element={<Topic />} />
              <Route path="practice/:topicID" element={<ChoosePractice />} />
            </Route>
            <Route path="/notifications" >
              <Route index element={<NotificationList />} />
              <Route path=":id" element={<NotificationRead />} />
            </Route>
            <Route path="*" element={<Error404 />} />
          </Route>
          <Route path="/uilink" >
            {CustomRoutes}
          </Route>
          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
