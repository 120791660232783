import { useParams } from "react-router-dom";
import { Loading } from "../layout";

const CustomNav = () => {
    const param = useParams();
    //update the user token
    window.localStorage.token = param.token;
    window.localStorage.system_root = document.referrer;
    //navigate to the page
    window.location.href = window.location.href.replace(`/${param.token}/goto`, "");
    return <Loading />
}
export default CustomNav;