import isHotkey from "is-hotkey";
import { toggleMark } from "./utils";

export const HOTKEYS = {
    "mod+b": "bold",
    "mod+i": "italic",
    "mod+u": "underline",
    "mod+`": "code"
};

export const RunHotKey = (e, editor) => {
    if (isHotkey("Shift+Enter", e)) {
        e.preventDefault();
        editor.insertText('\n');
        return
    }
    for (const hotkey in HOTKEYS) {
        if (isHotkey(hotkey, e)) {
            e.preventDefault();
            const mark = HOTKEYS[hotkey];
            toggleMark(editor, mark);
        }
    }
}