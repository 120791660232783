import { useEffect, useState } from "react";
import { NoteCreate } from "./create";
import { Hide } from "../../utils/hide";
import { TopicNoteResource } from "../../../Resources";
import { NoteView } from "./view";
import { Actions } from "../../utils/actions";

export const Notes = ({ topic }) => {
    const [create, setCreate] = useState(true);
    const [list, setList] = useState([]);
    const reload = () => TopicNoteResource.fetch({ topic_id: topic.id }, (resp) => {
        if (resp.status) setList(resp.body);
    });
    const actions = () => ({
        back: null,
        refresh: reload
    });
    useEffect(() => {
        reload();
        // eslint-disable-next-line
    }, [])
    return <div className="row">
        <div className="col-12">
            <div className="card shadow mb-3">
                <div className="card-header py-3">
                    <div className="row">
                        <div className="col-sm-5 col-md-4 col-lg-3 col-xl-2 text-nowrap">
                            <p className="text-primary m-0 fw-bold">Notes</p>
                        </div>
                        <div className="col-sm-7 col-md-8 col-lg-9 col-xl-10 text-end">
                            <Actions mod="gss_topic_note" actions={actions()}>
                                <button onClick={() => setCreate(!create)} className="btn btn-primary">New Note</button>
                            </Actions>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <Hide state={create}>
                        <div className="card mb-3">
                            <div className="card-body">
                                <NoteCreate topicID={topic.id} page={list.length + 1} back={() => setCreate(!create)} callback={() => { setCreate(!create); reload(); }} />
                            </div>
                        </div>
                    </Hide>
                    {list.map((v, i) => <NoteView key={i} data={v} reload={reload} />)}
                </div>
            </div>
        </div>
    </div>
}