import { Outlet } from "react-router-dom";
// import Breadcrumb from "./Breadcrumb";
// import Footer from "./footer";
import { TopBar } from "./top-bar";
import { ErrorBoundary } from "../utils/error_boundary";
import SideBar from "./side-bar";
import "../../assets/css/layout-style.css";
import { useState } from "react";


const PageContainer = () => {
    const [mobile, setMobile] = useState(false);
    // document.body.classList.add('bg-gradient-primary');
    const toTop = () => {
        window.scrollTo(0, 0);
    }
    return (
        <ErrorBoundary>
            <div className="container-fluid overflow-hidden vh-100">
                <div className="row h-100">
                    <SideBar mobile={mobile} setMobile={setMobile}/>
                    <div className="col-md-12 col-lg-9 col-xxl-10 h-100 p-0">
                        <TopBar toggleMobile={() => setMobile(!mobile)}/>
                        <div className="h-100 content px-3 scrollbar-secondary">
                            {/* <Breadcrumb /> */}
                            <ErrorBoundary>
                                <Outlet />
                            </ErrorBoundary>
                        </div>
                        {/* <Footer /> */}
                    </div>
                    <button className="btn btn-link border rounded d-inline scroll-to-top" onClick={toTop}>
                        <i className="fas fa-angle-up"></i>
                    </button>
                </div>
            </div>
        </ErrorBoundary>
    );
}

export default PageContainer;