import { useEffect, useRef, useState } from "react";
import { Transforms } from "slate";
import { useFocused, useSelected } from "slate-react";
import { Hide } from "../../utils/hide";
import { PopOverDirect } from "../../utils/popover";

export const ImageOptions = ({ editor }) => {
    const url = useRef();
    const file = useRef();
    const manager = useRef();
    const [method, setMethod] = useState("");
    const [show, setShow] = useState(false);
    const onInsert = (type) => {
        if (type === "file") {
            return insertImage(editor, file.current);
        }
        return insertImage(editor, url.current);
    }
    const fromManager = () => {
        setMethod("manager");
        setShow(true)
    }
    const pickFromManager = (e) => {
        let url = manager.current?.src.split("/");
        if (url && e.origin !== `${url[0]}//${url[2]}`) return;
        if (e.data.source !== "file-manager") return;
        insertImage(editor, { value: e.data.payload.data });
        setShow(false);
        setMethod("");
    }
    useEffect(() => {
        window.addEventListener("message", pickFromManager);
        return () => window.removeEventListener('message', pickFromManager);
        // eslint-disable-next-line
    }, []);

    return <div className='row mt-2'>
        <div className='col-12 mb-2'>
            <button onClick={() => setMethod("local")} className={`btn btn-${(method === "local") ? "primary" : "secondary"} me-2`} type="button" >Local File</button>
            <button onClick={() => setMethod("url")} className={`btn btn-${(method === "url") ? "primary" : "secondary"} me-2`} type="button" >From URL</button>
            <button onClick={fromManager} className={`btn btn-${(method === "manager") ? "primary" : "secondary"} me-2`} type="button" >File Manager</button>
        </div>
        <PopOverDirect show={show} size="xl" close={() => setShow(false)}>
            <iframe ref={manager} title="file manager" style={{ width: "100%", height: "80vh" }} src={`${window.localStorage.system_root}web/file-manager/post-copy`.replace("/web/web", "/web")} />
        </PopOverDirect>
        <Hide state={!(method === "local")}>
            <div className='col-12 col-md-6 col-lg-4'>
                <div className="input-group mb-3">
                    <input ref={file} type="file" className="form-control" name="name" title="Select image" placeholder="Select image" />
                    <span className="input-group-btn">
                        <button onClick={() => onInsert("file")} className="btn btn-primary" type="button" >Insert</button>
                    </span>
                </div>
            </div>
        </Hide>
        <Hide state={!(method === "url")}>
            <div className='col-12 col-md-6 col-lg-4'>
                <div className="input-group mb-3">
                    <input ref={url} type="text" className="form-control" name="name" placeholder="https://..." />
                    <span className="input-group-btn">
                        <button onClick={() => onInsert("url")} className="btn btn-primary" type="button" >Insert</button>
                    </span>
                </div>
            </div>
        </Hide>
    </div >
}

const insertImage = (editor, data) => {
    if (!data) return;
    // const { selection } = editor;
    let image = { type: 'image', src: data.value, style: "", children: [{ text: '' }] };
    const { files } = data
    if (files && files.length > 0) {
        const reader = new FileReader()
        const [mime] = files[0].type.split('/')
        if (mime === 'image') {
            reader.onload = (e) => {
                image = { ...image, src: e.target.result };
                Transforms.insertNodes(editor, image, { select: true });
            };
            reader.readAsDataURL(files[0]);
        }
    } else {
        image = { ...image, src: data.value };
        Transforms.insertNodes(editor, image, { select: true });
    }
}

export const Image = ({ src, alt, style, attributes, children }) => {
    const selected = useSelected();
    const focused = useFocused();
    return <>
        <img {...attributes} contentEditable={false} src={src} alt={alt}
            style={{ ...style, ...{ boxShadow: `${selected && focused ? '0 0 0 3px #B4D5FF' : 'none'}` } }}
        />
        {children}
    </>;
}