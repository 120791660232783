import { Button } from "react-bootstrap";
import { getNode, isBlockActive, isMarkActive, toggleBlock, toggleMark } from "../utils";
import { useEffect, useState } from "react";

export const BlockButton = ({ editor, format, icon, text }) => {
    text = text ? text : <i className={`fas ${icon}`}></i>;
    return (
        <Button variant="outline-primary" className="border-0 btn-sm"
            active={isBlockActive(editor, format)}
            onMouseDown={event => {
                event.preventDefault(); toggleBlock(editor, format)
            }}
        >
            {text}
        </Button>
    )
}
export const SelectList = ({ editor }) => {
    const formarts = [
        { label: "Paragraph", format: "paragraph" },
        { label: "Heading 1", format: "h1" },
        { label: "Heading 2", format: "h2" },
        { label: "Heading 3", format: "h3" },
        { label: "Heading 4", format: "h4" },
        { label: "Heading 5", format: "h5" },
        { label: "Heading 6", format: "h6" },
    ];
    const [val, setVal] = useState("paragraph");
    useEffect(() => {
        let node = getNode(editor);
        if (node) {
            setVal(node[0].type);
        }
        // eslint-disable-next-line
    }, [editor.selection]);

    const onChange = e => {
        e.preventDefault(); toggleBlock(editor, e.target.value);
    }
    return <select className="form-control d-inline" style={{ width: "100px" }} onChange={onChange} value={val}>
        {formarts.map(v => <option key={v.format} value={v.format}>{v.label}</option>)}
    </select>;
}

export const MarkButton = ({ editor, format, icon }) => {
    return (
        <Button variant="outline-primary" className="btn-sm me-1"
            active={isMarkActive(editor, format)}
            onMouseDown={e => {
                e.preventDefault(); toggleMark(editor, format)
            }} >
            <i className={`fas ${icon}`}></i>
        </Button>
    )
}
