import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './assets/css/bootstrap.min.css';
import './assets/css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/AuthContext';
import { FlashAlertProvider } from './contexts/flash-alert';
import { BreadCrumbProvider } from './contexts/BreadCrumbContext';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);
root.render(
  <FlashAlertProvider>
    <AuthProvider>
      <BreadCrumbProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </BreadCrumbProvider>
    </AuthProvider>
  </FlashAlertProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();