import { useContext, useState } from "react";
import { createContext } from "react";

const BreadCrumbContext = createContext({});

const BreadCrumbProvider = ({ children }) => {
    let initCrumb = [{ title: "Home", class: "home", icon: "fas fa-home", url: "/" }];
    const [crumbs, setCrumbs] = useState(initCrumb)
    const [message, setMessage] = useState(null);
    const [variant, setType] = useState(null);


    const Alert = (message, variant = "danger", callback = () => { }) => {
        setMessage(message);
        setType(variant);
        callback();
    };

    const ClearAlert = () => {
        setMessage(null);
        setType(null);
    };
    const UpdateCrumbs = (update = [{}]) => {
        setCrumbs(initCrumb.concat(update))
    }
    const PushCrumb = ({ crumb = {} }) => {
        setCrumbs(crumbs.concat([crumb]))
    }

    let value = { message, variant, crumbs, Alert, ClearAlert, UpdateCrumbs, PushCrumb };
    return <BreadCrumbContext.Provider value={value}>{children}</BreadCrumbContext.Provider>
}

function useBreadCrumb() {
    return useContext(BreadCrumbContext);
}

export { BreadCrumbProvider, useBreadCrumb };