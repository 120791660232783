
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const NumberLine = ({ labels = [] }) => { 

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                min: 0,
                max: labels.length
            },
        }
    };

    const data = {
        labels,
        datasets: [],
    };
    return (<span className="d-block mb-2 w-auto" style={{ height: '50px', width: '0.5vh' }}>
        <Line options={options} data={data} />
    </span>);
}