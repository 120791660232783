import { PopOverDirect } from "../../utils/popover";
import { Button } from "react-bootstrap";
import { GeneratedQuestions } from "../../customization/question";
import { useState } from "react";
import { Transforms } from "slate";
import { QuestionLoader } from "../../customization/question/util";

export const QuestionLoaderOption = ({ editor }) => {
    const [pop, setPop] = useState(false);
    const picker = (topic) => {
        setPop(false);
        Transforms.insertNodes(editor, { type: "question-loader", key: topic.id, children: [{ text: '' }] });
    };
    return <>
        <Button variant="outline-primary" onClick={() => setPop(!pop)} >QN</Button>
        <PopOverDirect show={pop} size="lg" close={() => setPop(false)} buttons={[]} >
            <div className="row">
                <div className="col-12">
                    <GeneratedQuestions picker={picker} />
                </div>
            </div>
        </PopOverDirect>
    </>
}

export const QuestionElement = ({ id, attributes, children }) => {
    return <div {...attributes} contentEditable={false}>
        <div className="card">
            <div className="card-body">
                <QuestionLoader id={id} />
            </div>
        </div>
        <div style={{ display: "none" }}>{children}</div>
    </div>;
}