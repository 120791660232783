import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFlashAlert } from "../../../contexts/flash-alert";
import { QuestionTemplateResource } from "../../../Resources";
import TitledContent from "../titled-content";
import { Actions } from "../../utils/actions";
import { Pagination } from "../../utils/paginate";
import { Confirm } from "../../utils/popover";

const QTList = () => {
    const [items, setItems] = useState([]);
    const [criteria, setCriteria] = useState({ page: 1, page_size: 10 });
    const [remove, setRemove] = useState();
    const flash = useFlashAlert();
    const navigate = useNavigate();
    useEffect(() => {
        loadList();
        // eslint-disable-next-line
    }, [criteria.page]);
    useEffect(() => {
        setPage(1);
        loadList();
        // eslint-disable-next-line
    }, [criteria.page_size]);

    const loadList = () => {
        QuestionTemplateResource.list(criteria, (resp) => {
            if (resp.status) {
                setItems(resp.body.items);
                setCriteria(values => ({ ...values, count: resp.body.count }));
                setCriteria(values => ({ ...values, pages: Math.ceil(resp.body.count / criteria.page_size) }));
            }
        });
    };
    const setPage = (page) => setCriteria(values => ({ ...values, page: page }));
    const handleCriteria = (e) => {
        const n = e.target.name;
        const v = e.target.value;
        setCriteria(values => ({ ...values, [n]: v }));
    }

    const removeQT = (item) => {
        QuestionTemplateResource.delete({ "id": item.id }, (r) => {
            if (r.status) {
                flash.display({ message: `${item.title} deleted`, type: "success", duration: 5000 });
                loadList();
            }
        });
    }
    const actions = () => {
        return {
            back: null,
            create: () => navigate("/uilink/question-template/create"),
        }
    }
    const lActions = (id) => {
        return {
            back: null,
            refresh: null,
            view: () => navigate(`/uilink/question-template/${id}`),
            delete: () => setRemove(id),
        }
    }
    return (<TitledContent title="Question Templates" actions={<Actions mod="gss_question_template" actions={actions()} />}>
        <div className="row">
            <div className="col-12 mb-2">
                <div className="card shadow">
                    <div className="card-header py-3">
                        <p className="text-primary m-0 fw-bold">All</p>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-5 col-md-4 col-lg-3 col-xl-2 text-nowrap">
                                <div className="input-group">
                                    <span className="input-group-text">Show</span>
                                    <select onChange={handleCriteria} value={criteria.page_size} name={"page_size"} className="d-inline-block form-select form-select-sm">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col">
                                <input type="search" className="form-control" placeholder="Search" />
                            </div>
                        </div>
                        <div className="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                            <table className="table my-0" id="dataTable">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Topic</th>
                                        <th>Type</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(items || []).map(item =>
                                        <tr key={item.id}>
                                            <td>{item.title}</td>
                                            <td>{item.topic.title}</td>
                                            <td>{item.type}</td>
                                            <td>
                                                <Actions mod="gss_question_template" actions={lActions(item.id)} >
                                                    <Confirm key={"confirm"} show={remove === item.id} callback={() => removeQT(item)}>You are about to delete {item.title}</Confirm>
                                                </Actions>
                                            </td>
                                        </tr>)}
                                </tbody>
                            </table>
                        </div>
                        <div className="row">
                            <div className="col-12 text-end">
                                <p>Showing {((criteria.page - 1) * criteria.page_size) + 1} to {((criteria.page - 1) * criteria.page_size) + items.length} of {criteria.count}</p>
                            </div>
                            <div className="col text-end">
                                <Pagination criteria={criteria} goto={setPage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </TitledContent>);
}

export default QTList;