import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { AttemptResource, AssessmentResource, AttemptQuestionResource } from "../../Resources";
import { useFlashAlert } from "../../contexts/flash-alert";
import { PopOver } from "../utils/popover";
import { Question, QuestionAnswers } from '../customization/question/util';
import Loading from "../layout/loading";
import { Hide } from "../utils/hide";
import { RetakeBtn } from "./utils";

export const AttemptAssessment = () => {
    const params = useParams();
    const flash = useFlashAlert();
    const navigate = useNavigate();
    const [attempt, setAttempt] = useState({});
    const [assessment, setAssessments] = useState({});
    const [save, setSave] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [questions, setQuestions] = useState(null);
    useEffect(() => {
        //get the assessment
        if (params.id) {
            AttemptResource.get(params.id, r => {
                if (r?.status) {
                    setAttempt(r.body);
                    setQuestions(r.body?.questions.reduce((prv, qty) => ({ ...prv, [qty.id]: qty }), {}));
                    AssessmentResource.open(r.body.assessment_id, r => {
                        if (r?.status) {
                            return setAssessments({ ...r.body, "attempts": [attempt] });
                        }
                    });
                }
            });
        }
        // eslint-disable-next-line
    }, []);
    //update a question attempt
    const qUpdate = (q) => {
        if (attempt.status !== 0) return;
        setQuestions(values => ({ ...values, [q.id]: q }));
        AttemptQuestionResource.reponse(q, resp => {
            //update that specific question
            if (resp.status) return setQuestions(values => ({ ...values, [q.id]: resp.body }));
            //handle errorr
            flash.display(<strong>answer not saved</strong>, "danger");
        });
    }
    //save attempt
    const trySave = ({ submit = false, callback = () => { } } = {}) => {
        //add the submit maker to attempt object
        AttemptResource.submit(submit ? { ...attempt, ...{ 'submit': true } } : attempt, resp => {
            callback();
            if (resp?.status) {
                if (submit) {
                    flash.display(<strong>Submited</strong>, "success", () => {
                        setTimeout(() => {
                            flash.Clear();
                        }, 3000);
                    });
                    //navigate to the result page 
                    return navigate(`/assessment/attempt/result/${attempt.id}`);
                }
                //navigate to open page
                flash.display(<strong>Saved</strong>, "success", () => {
                    setTimeout(() => {
                        flash.Clear();
                    }, 3000);
                });
                return navigate(`/assessment/open/${attempt.assessment_id}`);
            }
        });
    }
    //submit attempt
    const trySubmit = ({ callback = () => { } } = {}) => trySave({ submit: true, callback: callback });
    return <TestLayout save={save} setSave={setSave} submit={submit} setSubmit={setSubmit} trySave={trySave} trySubmit={trySubmit}>
        {!questions ? <Loading /> : <SingleMode questions={questions} attempt={attempt} assessment={assessment} update={qUpdate} setSave={setSave} setSubmit={setSubmit} />}
    </TestLayout>;
}

const TestLayout = ({ save = false, setSave = (state) => { }, submit = false, setSubmit = (state) => { }, trySave = () => { }, trySubmit = () => { }, children }) => {
    const renderSave = (show) => {
        if (!show) return <></>;
        return <PopOver title={`Confirm`} show={show} close={() => { setSave(false) }} buttons={[
            { text: "Yes", style: "success", callback: (c) => trySave({ callback: c }) },
            { text: "No", style: "danger", callback: (c) => c() }
        ]}>
            <p className="text-center">Are you sure you want to save your work?</p>
        </PopOver>
    };
    const renderSubmit = (show) => {
        if (!show) return <></>;
        return <PopOver title={`Confirm`} show={show} close={() => { setSubmit(false) }} buttons={[
            { text: "Yes", style: "success", callback: (c) => trySubmit({ callback: c }) },
            { text: "No", style: "danger", callback: (c) => c() }
        ]}>
            <p className="text-center">Are you sure you want to submit?</p>
        </PopOver>
    };
    return (
        <>
            {renderSave(save)}
            {renderSubmit(submit)}
            {/* <div className="col-12 mb-5">
                <div role="group" className="btn-group border-dark shadow float-end">
                    <button className={`btn btn-${props.mode === "single" ? "dark" : "light"}`} onClick={() => props.setMode("single")} type="button">Single Mode</button>
                    <button className={`btn btn-${props.mode === "multi" ? "dark" : "light"}`} onClick={() => props.setMode("multi")} type="button">Multi Mode</button>
                </div>
            </div> */}
            {children}
        </>);
}

const SingleMode = ({ questions = {}, attempt = {}, assessment = {}, update = () => { }, setSave = (state = false) => { }, setSubmit = (state = false) => { } }) => {
    let qKeys = Object.keys(questions);
    const [active, setActive] = useState(qKeys[0]);

    //return danger if button is active
    let aButton = (v) => {
        if (v === active) return "danger"
        if (questions[v].responses.length) return "success"
        return "secondary";
    };
    let navQuestions = (dir) => {
        let i = qKeys.indexOf(active);
        if (dir === "next") {
            //check if we are on the last key
            if (i === (qKeys.length - 1)) return setActive(qKeys[0]);
            return setActive(qKeys[++i]);
        }
        //check if we are on the first key
        if (i === 0) return setActive(qKeys[qKeys.length - 1]);
        return setActive(qKeys[--i]);
    };
    return (<div className="h-100 d-flex flex-column justify-content-between pt-4">
        <div>
            <Hide state={attempt.status !== 2}>
                <RetakeBtn assessment={assessment} className="btn btn-primary float-end" />
            </Hide>
            <h5>{assessment.title}</h5>
            <span className="text-secondary">Topic(s): {assessment.configs?.map(v => v.topic.name).join(", ")}</span>
            <span className="text-secondary d-block mt-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                    <path d="M4 6H20M4 10H20M4 14H20M4 18H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
                {qKeys.indexOf(active) + 1}/{qKeys.length} question(s)
            </span>
            <span className="text-secondary d-block">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                    <path d="M12 8V12L15 15M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
                15 minutes
            </span>
            <hr className="my-4" />
        </div>
        <div className="mx-auto">
            <Question question={questions[active]} attempt={questions[active]} update={update} />
            <Hide state={attempt.status !== 2}>
                <QuestionAnswers question={questions[active]} />
            </Hide>
        </div>
        <div className="text-center">
            {qKeys.map((v, i) => <button key={v} className={`btn border rounded-circle btn-${aButton(v)}`} onClick={() => setActive(v)} type="button">{i + 1}</button>)}
        </div>
        <div className="d-flex justify-content-between mb-4">
            <button onClick={() => navQuestions("prev")} className="btn btn-dark border rounded-pill" type="button">
                <i className="fas fa-chevron-left"></i>
            </button>
            <Hide state={attempt.status !== 0}>
                <div>
                    <button className="btn btn-warning border rounded-pill" onClick={() => { setSave(true) }} style={{ marginRight: "5px" }} >Save &amp; Exit</button>
                    <button className="btn btn-success border rounded-pill" onClick={() => { setSubmit(true) }} style={{ marginLeft: "5px" }}>Submit</button>
                </div>
            </Hide>
            <button onClick={() => navQuestions("next")} className="btn btn-dark border rounded-pill" type="button">
                <i className="fas fa-chevron-right"></i>
            </button>
        </div>
    </div>);
}