import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'

export const PopOverDirect = ({ title, show = false, size = "md", close = () => { }, buttons = [{ style: "success", text: "yes", callback: (close) => { } }], children }) => {
    let footer = !buttons.length ? null : <Modal.Footer>{buttons.map((v, i) => <Button key={i} variant={v.style} onClick={() => v.callback(close)}>{v.text}</Button>)}</Modal.Footer>;
    return (
        <Modal show={show} size={size} onHide={close} centered={true}>
            {title ? <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header> : null}
            <Modal.Body>{children}</Modal.Body>
            {footer}
        </Modal>
    );
}

export const PopOver = ({ title, show = false, close = () => { }, buttons = [{ style: "success", text: "yes", callback: (close) => { } }], children }) => {
    const [mshow, setMshow] = useState(show);
    const _close = () => { setMshow(!mshow); close() };
    return <PopOverDirect title={title} show={mshow} close={_close} buttons={buttons} children={children} />;
}

export const Confirm = ({ show = false, callback = () => { }, close = () => { }, children }) => {
    if (!show) return null;
    return <PopOver title={`Confirm`} show={show} close={close} buttons={[
        { text: "Yes", style: "success", callback: (c) => { callback(); c(); } },
        { text: "No", style: "danger", callback: (c) => c() }
    ]}>{children}</PopOver>
};