import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { TopicResource, ClassResource, ProfileResource } from '../../../Resources';
import TitledContent from "../titled-content";
import { CForm } from './form';
import { Actions } from '../../utils/actions';
import { deserialize, serialize } from '../../slate';

const Create = () => {
    let navigate = useNavigate();
    const param = useParams();
    const [topics, setTopics] = useState([]);
    const [instructors, setInstructors] = useState([]);
    const [data, setData] = useState({});
    const [alert, setAlert] = useState({});
    const [editorValue, setEditorValue] = useState();
    useEffect(() => {
        if (!editorValue) return;
        setData(values => ({ ...values, description: serialize({ children: editorValue }) }));
    }, [editorValue])

    useEffect(() => {
        TopicResource.fetch({ parent_id: 1 }, r => {
            if (r.status) {
                setTopics(r.body);
                return
            }
            setAlert(r.body);
        });
        ProfileResource.find({}, r => {
            if (r.status) {
                return setInstructors(r.body);
            }
            setAlert(r.body);
        })

        ClassResource.get(param.id, r => {
            if (r?.status) {
                setEditorValue(deserialize(r.body?.description || ""));
                setData(values => ({ ...values, ...r.body }));
                return
            }
            setAlert(r.body);
        });
    }, [param.id]);

    const handleInput = (e) => {
        const n = e.target.name;
        const v = e.target.value;
        const c = e.target.checked;
        switch (n) {
            case "photo":
                const file = e.target.files[0];
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = function (e) {
                    setData(values => ({ ...values, preview: reader.result }));
                };
                setData(values => ({ ...values, [n]: file }))

                break;
            case "active":
            case "restricted":
                setData(values => ({ ...values, [n]: c }));
                break;
            default:
                setData(values => ({ ...values, [n]: v }))
                break;
        }
        setAlert(null);
    }

    const handleSubmit = () => {
        setAlert(null);
        // Create an object of formData
        const formData = new FormData();
        for (const [key, input] of Object.entries(data)) {
            if (key === "photo" && input.name) {
                formData.append(key, input, input.name)
            } else {
                formData.append(key, input)
            }
        }
        ClassResource.update(formData, r => {
            if (r?.status) {
                if (r?.status) return navigate("/uilink/class/" + r.body.id);
            } else {
                setAlert(r?.body);
            }
        });
    }

    const actions = () => {
        return {
            back: () => navigate(`/uilink/class/${param.id}`),
            save: () => handleSubmit(),
        }
    }

    return (
        <TitledContent title={`Class - Edit`} actions={<Actions mod="gss_class" actions={actions()} />}>
            <CForm onChange={handleInput} data={data} topics={topics} instructors={instructors} actions={actions()} alerts={alert}
                editorValue={editorValue}
                setEditorValue={setEditorValue} />
        </TitledContent >
    );
}
export default Create;