import { Button, ButtonGroup } from "react-bootstrap";

export const Pagination = ({ page = 1, size = 0, goto = () => { } }) => {
    const list = [];
    const start = Math.max(0, page - 10) + 1
    const end = Math.min(size, start + 10) + 1
    for (let i = start; i < end; i++) {
        list.push(<Button key={i} onClick={() => goto(i)} variant={i === page ? 'primary' : 'outline-secondary'}>{i}</Button>);
    }
    return (
        <ButtonGroup>
            <Button variant="outline-secondary" onClick={() => goto(1)}><span>«</span></Button>
            {list}
            <Button variant="outline-secondary" onClick={() => goto(size)}><span>»</span></Button>
        </ButtonGroup>);
}