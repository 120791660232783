import { useNavigate } from "react-router-dom";
import { PopOverDirect } from "../utils/popover";
import { useEffect, useState } from "react";
import { AttemptResource } from "../../Resources";
import { PopIcon } from "../utils/custom_icons";
import { Hide } from "../utils/hide";

export const RetakeBtn = ({ assessment, className }) => {
    const navigate = useNavigate();
    const [pop, setPop] = useState(false);
    const [duePop, setDuePop] = useState(false);
    const [btntxt, setBtntxt] = useState("");
    let startHandler = () => {
        if (btntxt.toLowerCase() === "start") {
            return navigate(`/assessment/open/${assessment.id}`);
        }
        //create an attempt
        AttemptResource.create({ assessment_id: assessment.id }, r => {
            //navigate to the assessment
            if (r?.status) {
                setPop(false);
                return navigate(`/assessment/attempt/${r.body.id}`);
            }
        });
    }
    useEffect(() => {
        let txt = assessment.attempts?.length ? "Retake" : "Start";
        txt = assessment.attempts?.reduce((p, c) => p || c.status === 0, false) ? "Continue" : txt;
        setBtntxt(txt)
    }, [assessment.attempts]);

    if((assessment.due_at && Date.parse(assessment.due_at) < Date.now())){
        return;
    }
    return <>
        <button onClick={() => setPop(true)} className={className ? className : "btn btn-sm text text-primary"} type="button">
            <b>{btntxt}</b>
        </button>
        <PopOverDirect show={pop} close={() => setPop(false)} buttons={[]}>
            <div className="col-12">
                <div className="text-center">
                    <PopIcon />
                    <h6>Take Assessment</h6>
                    <p>You're about to {btntxt} "{assessment.title}".</p>
                </div>
                <div className="row">
                    <div className="col-6">
                        <button className="btn btn-outline-dark w-100 fw-bold" onClick={() => setPop(false)} type="button">Cancel</button>
                    </div>
                    <div className="col-6">
                        <button className="btn btn-primary w-100 fw-bold" onClick={startHandler} type="button">{btntxt}</button>
                    </div>
                </div>
                <div className="d-flex justify-content-between"></div>
            </div>
        </PopOverDirect>
        <PopOverDirect show={duePop} close={() => setDuePop(false)} buttons={[]}>
            <div className="col-12">
                <div className="text-center">
                    <p>Sorry you can't {btntxt} a due assessment.</p>
                </div>
                <div className="row">
                    <div className="col-12">
                        <button className="btn btn-outline-dark w-100 fw-bold" onClick={() => setDuePop(false)} type="button">Close</button>
                    </div>
                </div>
            </div>
        </PopOverDirect>
    </>
}

export const ViewBtn = ({ assessment, readOnly = false }) => {
    const [pop, setPop] = useState(false);
    const avgScore = (attempts) => {
        let val = parseInt(attempts.reduce((p, c) => p + (c.point / c.total_point * 100), 0) / attempts.length)
        return isNaN(val) || !isFinite(val) ? "--" : val;
    }
    if (!assessment?.attempts?.length) return;
    return <>
        <button onClick={() => setPop(true)} className="btn btn-sm text" type="button">
            <b className="text-secondary">View</b>
        </button>
        <PopOverDirect show={pop} close={() => setPop(false)} buttons={[]}>
            <div className="col-12">
                <div className="text-center">
                    <PopIcon />
                    <h6>View Assessment</h6>
                    <p>Here's a result summary.</p>
                </div>
                <div className="row">
                    <div className="col-12 border-bottom">
                        <h6>Topic(s)</h6>
                        <span>{assessment.configs.map(v => v.topic.name).join(", ")}</span>
                    </div>
                    <div className="col-6 border-bottom">
                        <h6>Class</h6>
                        <span>{assessment.class?.name}</span>
                    </div>
                    <div className="col-6 border-bottom">
                        <h6>Questions</h6>
                        <span>{assessment.configs.reduce((p, c) => p + c.count, 0)}</span>
                    </div>
                    <div className="col-6 border-bottom">
                        <h6>Avg. Score</h6>
                        <span>{avgScore(assessment.attempts)}%</span>
                    </div>
                    <div className="col-6 border-bottom">
                        <h6>Date</h6>
                        <span>{new Date(assessment.updated_at).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}</span>
                    </div>
                    <div className="col-12 mt-2">
                        <div className="table-responsive">
                            <table className="table table-striped table-sm">
                                <thead>
                                    <tr>
                                        <th>Attempt(s)</th>
                                        <th>Score</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {assessment.attempts.map((v, k) =>
                                        <tr key={k}>
                                            <td>{new Date(v.created_at).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}</td>
                                            <td>{v.status === 2 ? parseInt(v.point / v.total_point * 100) : "-"}%</td>
                                            <td className="text-end">
                                                <a className={`btn btn-sm btn-success ${v.status === 0 ? "d-none" : ""}`} href={`/assessment/attempt/result/${v.id}`} >Result</a>
                                            </td>
                                        </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="w-100 mt-3"></div>
                    <Hide state={readOnly}>
                        <div className="col-6">
                            <button className="btn btn-outline-dark w-100 fw-bold" onClick={() => setPop(false)} type="button">Close</button>
                        </div>
                        <div className="col-6">
                            <RetakeBtn className="btn btn-primary w-100 fw-bold" assessment={assessment} />
                        </div>
                    </Hide>
                    <Hide state={!readOnly}>
                        <div className="col-12">
                            <button className="btn btn-outline-dark w-100 fw-bold" onClick={() => setPop(false)} type="button">Close</button>
                        </div>
                    </Hide>
                </div>
                <div className="d-flex justify-content-between"></div>
            </div>
        </PopOverDirect>
    </>
}