import { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { useFlashAlertData} from "../../../contexts/flash-alert";

const FlashAlert = () => {
    let data = useFlashAlertData();
    const [message, setMessage] = useState();
    const [type, setType] = useState(data.type || 'danger');
    const [duration, setDuration] = useState(data.duration || 5000);
    const [remaining, setRemaining] = useState(data.duration || 5000);
    const [visible, setVisible] = useState(false);
    const [start, setStart] = useState();
    const [timer, setTimer] = useState();
    const [tick, setTick] = useState()

    useEffect(() => {
        if (!data.message) return;
        clearTimeout(timer);
        clearInterval(tick);
        setMessage(data.message);
        setType(data.type || 'danger');
        setDuration(data.duration || 5000);
        setRemaining(data.duration || 5000);
        setVisible(true);
        resume();
        return () => {
            clearTimeout(timer);
            clearInterval(tick);
        }
        // eslint-disable-next-line
    }, [data]);

    const resume = () => setStart(new Date());
    useEffect(() => {//run once start changes
        if (!start) return;
        let tk = setInterval(() => setRemaining(remaining - (new Date() - start)), 100);
        setTick(tk);
        setTimer(setTimeout(() => {
            setVisible(false);
            clearInterval(tk);
        }, remaining));
        // eslint-disable-next-line
    }, [start]);

    const pause = () => {
        clearTimeout(timer);
        clearInterval(tick);
        setRemaining(remaining - (new Date() - start));
    }

    if (!visible) return null;
    return (
        <div className={`flash-alert-bottom text-center`} onMouseEnter={() => pause()} onMouseLeave={() => resume()}>
            <div className={`alert alert-${type} d-inline-block`}>
                <p>{message}</p>
            </div>
            <ProgressBar variant={type} min={'0'} max={duration} now={remaining}></ProgressBar>
        </div>
    );
}
export default FlashAlert;