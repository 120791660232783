import { Config } from "./config";

const ProfileResource = {
    base: "/api/profile",
    me(callback) {
        Config.Connection()
            .get(`${this.base}/me`)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response));
    },
    list(criteria, callback) {
        Config.Connection()
            .get(this.base, { params: criteria })
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    get(id, callback) {
        Config.Connection()
            .get(`${this.base}/${id}`)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response));
    },
    update(data, callback) {
        Config.Connection()
            .put(this.base, data)
            .then(resp => {
                callback(resp.data);
            })
            .catch(err => callback(err.response?.data));
    },
    find(criteria, callback) {
        Config.Connection()
            .post(`${this.base}/find`, criteria)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
};
export default ProfileResource;