import { Config } from "./config";

const AssessmentResource = {
    base: "/api/custom/school/assessment",
    find(criteria, callback) {
        Config.Connection()
            .post(`${this.base}/find`, criteria)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    create(data, callback) {
        Config.Connection()
            .post(this.base, data)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    update(data, callback) {
        Config.Connection()
            .put(this.base, data)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    get(id, callback) {
        Config.Connection()
            .get(`${this.base}/${id}`)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    list(criteria, callback) {
        Config.Connection()
            .get(this.base, { params: criteria })
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    listSubmit(criteria, callback) {
        Config.Connection()
            .post(`${this.base}/list-submit`, criteria)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    open(id, callback) {
        Config.Connection()
            .get(`${this.base}/open/${id}`)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    practice(criteria, callback) {
        Config.Connection()
            .post(`${this.base}/practice`, criteria)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    }
};

export default AssessmentResource;