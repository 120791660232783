import { useToken } from "../../contexts/AuthContext";
import { useLocation, Navigate, } from "react-router-dom";

function RequireAuth({ children }) {
    let token = useToken();
    let location = useLocation();

    if (token) {
        return children;
    }
    return <Navigate to="/sign-in" replace state={{ from: location }} />;
}

export default RequireAuth;