import { useNavigate } from "react-router-dom";
import "../../assets/css/Animation-Cards.css";

const TopicCard = ({ id="", type="", title="", photo="" }) => {
    let navigate = useNavigate();
    const renderPhoto = photo => {
        if (photo) {
            return <img className="card-img-top w-100 d-block card-image" src={"http://" + photo} alt="" />
        }
    }
    return (
        <div className="col-12 col-sm-6 col-xl-4 col-xxl-3 mb-4" onClick={() => { navigate("/" + type + "/" + id); }}>
            <div className="card cards-shadown cards-hover link">
                {renderPhoto(photo)}
                <div className="card-body">
                    <h4 className="card-title">{title}</h4>
                </div>
            </div>
        </div>
    );
}

export default TopicCard;