
import { useState } from 'react'
import { createEditor } from 'slate'
import { withHistory } from 'slate-history'
import { Slate, Editable, withReact, useSlate } from 'slate-react'
import { Hide } from '../utils/hide'
import { Menu } from './context_menu'
import { RunHotKey } from './events'
import { withBehaviour } from './plugins/behaviour'
import withKeyCommands from './plugins/key_commands'
// import { parse_deserialize, serialize } from './schema'
import { BlockButton, ExpandButton, ImageOptions, MarkButton, Properties, TableOptions } from './tool_bar'
import { Element, Leaf } from './utils'
import { QuestionLoaderOption } from './tool_bar/question'
import { SelectList } from './tool_bar/rich_text' 

export const Editor = ({ setValue = () => { }, initialValue = [{ type: 'paragraph', style: "", children: [{ text: '' }], },], }) => {
    const [editor] = useState(() => withReact(withHistory(withKeyCommands(withBehaviour(createEditor())))));
    const [showmenu, setShowmenu] = useState(false);
    const [showprop, setShowprop] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const renderElement = props => <Element {...props} />;
    const renderLeaf = props => <Leaf {...props} />;

    return (
        <div className='form-control'>
            <Slate editor={editor} value={initialValue}
                onChange={value => {
                    const isAstChange = editor.operations.some(op => 'set_selection' !== op.type)
                    if (!isAstChange) return;
                    setValue(editor.children);
                    // console.log(editor.children);
                    // // Serialize the value and save the string value to Local Storage.
                    // const html = serialize(editor);
                    // console.log(html);
                    // console.log(parse_deserialize(html)); 
                }}>
                <Menu show={showmenu} pos={position} showProp={setShowprop} close={() => setShowmenu(false)} />
                <Hide state={!showprop}>
                    <Properties editor={editor} show={setShowprop} setShow={setShowprop} />
                </Hide>
                <Toolbar />
                <Editable
                    renderLeaf={renderLeaf}
                    renderElement={renderElement}
                    placeholder="Enter some rich text…"
                    spellCheck
                    onKeyDown={e => {
                        RunHotKey(e, editor);
                    }}
                    onContextMenu={e => {
                        e.preventDefault();
                        setPosition({ x: e.clientX, y: e.clientY })
                        setShowmenu(true);
                    }}
                />
            </Slate>
        </div>
    )
}


const Toolbar = ({ custom = () => { } }) => {
    const editor = useSlate();
    const [hto, setHto] = useState(true); //hide/show table options
    const [img, setImg] = useState(true); //hide/show table options
    return (
        <div>
            <MarkButton editor={editor} format="bold" icon="fa-bold" />
            <MarkButton editor={editor} format="italic" icon="fa-italic" />
            <MarkButton editor={editor} format="underline" icon="fa-underline" />
            <MarkButton editor={editor} format="code" icon="fa-code" />
            <SelectList editor={editor} />
            <BlockButton editor={editor} format="block-quote" icon="fa-quote-left" />
            <BlockButton editor={editor} format="numbered-list" icon="fa-list-ol" />
            <BlockButton editor={editor} format="bulleted-list" icon="fa-list-ul" />
            <BlockButton editor={editor} format="left" icon="fa-align-left" />
            <BlockButton editor={editor} format="center" icon="fa-align-center" />
            <BlockButton editor={editor} format="right" icon="fa-align-right" />
            <BlockButton editor={editor} format="justify" icon="fa-align-justify" />
            <ExpandButton editor={editor} icon="fa-table" toggle={setHto} current={hto} />
            <ExpandButton editor={editor} icon="fa-image" toggle={setImg} current={img} />
            <QuestionLoaderOption editor={editor} />
            {/* <PropButton editor={editor} show={showprop} setShow={setShowprop} /> */}
            <Hide state={hto}>
                <TableOptions editor={editor} />
            </Hide>
            <Hide state={img}>
                <ImageOptions editor={editor} />
            </Hide>
            {custom(editor)}
            <hr />
        </div>
    )
}