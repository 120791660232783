import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUgroup } from "../../contexts/AuthContext";
import { Hide } from "./hide";
/**
 * Actions component used to control the action buttons available in a module
 * @param {Object} params - the parameter object.
 * @param {string} params.mod - the module code the action will be implimenting.
 * @param {string} params.iconOnly - when set to true, only icons will show.
 * @param {Object} params.actions - actions contains mapped action function to be executed.eg: {list: null,view: null,create: null,edit: null,save: null,update: null,delete: null,back: () => navigate(-1)}
 * @param {Object[]} params.extra - a list of react components to be rendered in the buton group
 * @param {Object} params.children - the react component to be displeyed inplace of the action buttons
 * @returns 
 */
export const Actions = ({ mod = "", iconOnly = false, actions = {}, children }) => {
    const permits = useUgroup();
    const navigate = useNavigate();
    const [modAct, setModAct] = useState({});
    const [super_g, setSuper] = useState(false);
    if (!Object.keys(actions).includes("back")) actions.back = () => navigate(-1);
    if (!Object.keys(actions).includes("refresh")) actions.refresh = () => window.location.reload();

    useEffect(() => {
        const module = permits.permits?.filter(p => p.mod === mod)[0] || {};
        setSuper(permits.super_group);
        setModAct(module.actions?.split(",").reduce((p, c) => { return { ...p, [c]: true } }, {}) || {});
        // eslint-disable-next-line
    }, [permits]);

    const hide = (permit, action) => {
        let act = action instanceof Object && (typeof action) === "object" ? action.callback : action
        return (permit || super_g) && act ? false : true;
    }
    const onclick = (permit, callback = () => { }) => {
        if (!permit && !super_g) return;
        if (callback instanceof Function) return callback();
        if (callback instanceof Object && callback.callback instanceof Function) return callback.callback();
    }
    const btnText = (text, action) => action instanceof Object && action.text ? action.text : text;

    return <div role="group" className="btn-group btn-group-sm">
        <Hide state={hide(modAct.list, actions.view)}>
            <button onClick={() => onclick(modAct.list, actions.view)} className={`btn btn-primary`} type="button">
                <i className="fas fa-save fa-sm text-white"></i><Hide state={iconOnly}> {btnText("View", actions.view)}</Hide>
            </button>
        </Hide>
        <Hide state={hide(modAct.create, actions.create)}>
            <button onClick={() => onclick(modAct.create, actions.create)} className={`btn btn-primary`} type="button">
                <i className="fas fa-plus fa-sm text-white"></i><Hide state={iconOnly}> {btnText("New", actions.create)}</Hide>
            </button>
        </Hide>
        <Hide state={hide(modAct.update, actions.edit)}>
            <button onClick={() => onclick(modAct.update, actions.edit)} className={`btn btn-dark`} type="button">
                <i className="fas fa-edit fa-sm text-white"></i><Hide state={iconOnly}> {btnText("Edit", actions.edit)}</Hide>
            </button>
        </Hide>
        <Hide state={hide(modAct.create, actions.save)}>
            <button onClick={() => onclick(modAct.create, actions.save)} className={`btn btn-primary`} type="button">
                <i className="fas fa-save fa-sm text-white"></i><Hide state={iconOnly}> {btnText("Save", actions.save)}</Hide>
            </button>
        </Hide>
        <Hide state={hide(modAct.update, actions.update)}>
            <button onClick={() => onclick(modAct.update, actions.update)} className={`btn btn-primary`} type="button">
                <i className="fas fa-save fa-sm text-white"></i><Hide state={iconOnly}> {btnText("Update", actions.update)}</Hide>
            </button>
        </Hide>
        <Hide state={hide(modAct.delete, actions.delete)}>
            <button onClick={() => onclick(modAct.delete, actions.delete)} className={`btn btn-danger`} type="button">
                <i className="fas fa-times-circle fa-sm text-white"></i><Hide state={iconOnly}> {btnText("Delete", actions.delete)}</Hide>
            </button>
        </Hide>
        <Hide state={hide(true, actions.refresh)}>
            <button onClick={() => onclick(true, actions.refresh)} className={`btn btn-warning`} type="button">
                <i className="fas fa-redo"></i><Hide state={iconOnly}> {btnText("Refresh", actions.refresh)}</Hide>
            </button>
        </Hide>
        <Hide state={hide(true, actions.back)}>
            <button onClick={() => onclick(true, actions.back)} className={`btn btn-secondary`} type="button">
                <i className="fas fa-arrow-circle-left"></i><Hide state={iconOnly}> {btnText("Back", actions.back)}</Hide>
            </button>
        </Hide>
        {children}
    </div>;
}

export const OnPermit = ({ mod = "", action = "", children }) => {
    const permits = useUgroup();
    const [modAct, setModAct] = useState({});
    const [super_g, setSuper] = useState(false);
    useEffect(() => {
        const module = permits.permits?.filter(p => p.mod === mod)[0] || {};
        setSuper(permits.super_group);
        setModAct(module.actions?.split(",").reduce((p, c) => { return { ...p, [c]: true } }, {}) || {});
        // eslint-disable-next-line
    }, [permits]);
    if (!action.length || super_g) return children;
    if (!modAct[action]) return null;
    return children;
}