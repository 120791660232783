import { useNavigate } from "react-router-dom";
const Error404 = () => {
    let navigate = useNavigate();
    // const goHome = () => {
    //     navigate("./home")
    // }
    const goBack = () => {
        navigate(-1);
    }
    return (<div className="d-flex justify-content-center align-items-center w-100 h-100">
        <div className="card shadow-lg o-hidden border-0 my-5 loading">
            <div className="card-body p-2">
                <h1 className="text-danger">Error 404</h1>
                <h4 className="text-dark">Page not fount</h4>
                <p>Don't worry you didn't break the internet kindly go back and all will fine</p>
                {/* <button className="btn btn-primary" onClick={goHome}>Home</button> */}
                <button className={`btn float-end btn-primary${window.history.length < 3 ? " d-none" : ""}`} onClick={goBack}>Go Back</button>
            </div>
        </div>
    </div>);
}

export default Error404