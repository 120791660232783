import { Actions } from "../../utils/actions"
import { ShowAlert } from "../../utils/alerts"

export const TForm = ({ data, errors, topics = [], actions = {}, onChange }) => {
    const disabled = onChange == null;
    return <div className="row">
        <div className="col-12 mb-2">
            <div className="card shadow mb-3">
                <div className="card-header py-3">
                    <p className="text-primary m-0 fw-bold">Topic/Target Information</p>
                </div>
                <div className="card-body">
                    <ShowAlert errors={errors} tag="error" />
                    <div className="row">
                        <div className="col-12 col-sm-6 col-xl-4">
                            <label className="col-form-label w-100" >
                                <strong>Name</strong>
                                <input value={data?.name ?? ""} onChange={onChange} disabled={disabled} name="name" type="text" className="form-control" />
                                <ShowAlert errors={errors} tag="name_error" />
                            </label>
                        </div>
                        <div className="col-12 col-sm-6 col-xl-4">
                            <label className="col-form-label w-100" >
                                <strong>Parent</strong>
                                <select value={data.parent_id ?? ""} onChange={onChange} disabled={disabled} name="parent_id" className="form-select" >
                                    <option value="" >-select parent-</option>
                                    {topics.map((v, i) => <option key={i} value={v.id} >{v.title}</option>)}
                                </select>
                                <ShowAlert errors={errors} tag="parent_id_error" />
                            </label>
                        </div>
                        <div className="col-12 mb-2">
                            <label className="form-label w-100" >
                                <strong>Description</strong>
                            </label>
                            <textarea value={data?.description ?? ""} onChange={onChange} disabled={disabled} name="description" className="form-control"></textarea>
                            <ShowAlert errors={errors} tag="description_error" />
                        </div>
                    </div>
                    <div className="text-end">
                        <Actions mod="gss_question_template" actions={actions} />
                    </div>
                </div>
            </div>
        </div>
    </div>
}