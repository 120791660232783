import { Config } from "./config";

const UserResource = {
    base: "/api/user",
    list(criteria, callback) {
        Config.Connection()
            .get(this.base, { params: criteria })
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    get(id, callback) {
        Config.Connection()
            .get(`${this.base}/${id}`)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    create(data, callback) {
        Config.Connection()
            .post(this.base, data)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    update(data, callback) {
        Config.Connection()
            .put(this.base, data)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    modules(callback) {
        Config.Connection()
            .get(`${this.base}/permits`)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    }
};

export default UserResource;