import { Config } from "./config";

const TopicNoteResource = {
    base: "/api/custom/school/topic-note",
    fetch( criteria, callback) {
        Config.Connection() 
            .post(`${this.base}/find`, criteria)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    create( data, callback) {
        Config.Connection()
            .post(`${this.base}`, data)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    update( data, callback) {
        Config.Connection()
            .put(`${this.base}`, data)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    get( id, callback) {
        Config.Connection()
            .get(`${this.base}/${id}`)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    getSub(criteria, callback) {
        Config.Connection()
            .post(`${this.base}/sub`, criteria)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    practice( criteria, callback) {
        Config.Connection()
            .post(`${this.base}/practice`, criteria)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    }
};

export default TopicNoteResource;