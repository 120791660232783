import { Config } from "./config";

const ClassResource = {
    base: "/api/custom/school/classes",
    list(criteria, callback) {
        Config.Connection()
            .get(this.base, { params: criteria })
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    find(data, callback) {
        Config.Connection()
            .post(`${this.base}/find`, data)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    create(data, callback) {
        Config.Connection()
            .post(this.base, data)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    update(data, callback) {
        Config.Connection()
            .put(this.base, data)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    get(id, callback) {
        Config.Connection()
            .get(`${this.base}/${id}`)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    students(criteria, callback) {
        Config.Connection()
            .get(`${this.base}/students`, { params: criteria })
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    addStudents(data, callback) {
        Config.Connection()
            .put(`${this.base}/add-students`, data)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    dropStudents(data, callback) {
        Config.Connection()
            .delete(`${this.base}/drop-students`, { params: data })
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    addEvent(data, callback) {
        Config.Connection()
            .put(`${this.base}/add-event`, data)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    dropEvent(data, callback) {
        Config.Connection()
            .delete(`${this.base}/drop-event`, { params: data })
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    questionable(data, callback) {
        Config.Connection()
            .post(`${this.base}/questionable`, data)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
};

export default ClassResource;