export const Menu = ({ editor, show, pos, showProp = () => { }, close = () => { } }) => {
    if (!show) return null;
    const openProps = () => {
        close();
        showProp(true);
    }
    return (<ul className="dropdown-menu show" style={{ position: 'fixed', top: pos.y, left: pos.x }}>
        <li><span className="dropdown-item" onClick={openProps} >Properties</span></li>
        <li><span className="dropdown-item" onClick={close} >Close</span></li>
    </ul>);
}