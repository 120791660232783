import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TopicResource } from "../../../Resources";
import TitledContent from "../titled-content";
import { Actions } from "../../utils/actions";
import { TForm } from "./form";

const TopicCreate = () => {
    const [data, setData] = useState({});
    const [topics, setTopics] = useState([]);
    const [errors, setErrors] = useState();
    const navigate = useNavigate();
    useEffect(() => {
        // eslint-disable-next-line
    }, []);
    //run once
    useEffect(() => {
        //fetch the available topics
        TopicResource.fetch({}, resp => {
            if (resp.status) setTopics(resp.body);
        });
        // eslint-disable-next-line
    }, []);

    const handleInput = (e, key) => {
        setErrors(null);
        const n = e.target.name;
        const v = e.target.value;
        setData(values => ({ ...values, [n]: v }));
    }
    const handleSubmit = () => {
        TopicResource.create(data, r => {
            if (r.status) {
                navigate(`/uilink/topic`);
            } else {
                setErrors(r?.body);
            }
        });
    }

    const actions = () => ({
        save: () => handleSubmit(),
    });
    return <TitledContent title="Topic/Target" actions={<Actions mod="gss_question" actions={actions()} />}>
        <TForm data={data} topics={topics} errors={errors} actions={actions()} onChange={handleInput} />
    </TitledContent >;
}

export default TopicCreate;