import { Text } from "slate";
import { jsx } from "slate-hyperscript";
import { Element, Leaf } from "./utils";

export const serialize = node => {
    if (Text.isText(node)) {
        let string = escapeHTML(node.text);
        if (node.bold) {
            string = `<strong>${string}</strong>`;
        }
        if (node.code) {
            string = `<code>${string}</code>`;
        }
        if (node.italic) {
            string = `<em>${string}</em>`;
        }
        if (node.underline) {
            string = `<u>${string}</u>`;
        }
        return string
    }

    const children = node.children.map(n => serialize(n)).join('');

    switch (node.type) {
        case "block-quote":
            return `<blockquote>${children}</blockquote>`;
        case "bulleted-list":
            return `<ul>${children}</ul>`;
        case "h1":
        case "h2":
        case "h3":
        case "h4":
        case "h5":
        case "h6":
            return `<${node.type}>${children}</${node.type}>`;
        case "list-item":
            return `<li>${children}</li>`;
        case "numbered-list":
            return `<ol>${children}</ol>`;
        case "image":
            return `<img class="${node.class || ''}" style="${node.style || ''}" src="${node.src}" alt="${node.alt || ''}" />`;
        // case "video":
        //   return <VideoElement {...props} />;
        case "link":
            return `<a href="${escapeHTML(node.href)}>${children}</a>`;
        case "table":
            return `<table style="${node.style || ''}" class="${node.class || ''}">${children}</table>`;
        case "table-body":
            return `<tbody>${children}</tbody>`;
        case "table-row":
            return `<tr>${children}</tr>`;
        case "table-head":
            return `<th style="${node.style || ''}">${children}</th>`;
        case "table-cell":
            return `<td style="${node.style || ''}">${children}</td>`;
        case 'paragraph':
            return `<p style="${node.style || ''}" class="${node.class || ''}">${children}</p>`;
        case "question-loader":
            return `<div type="${node.type}" key="${node.key}">Question ${node.key}</div>`
        default:
            return children;
    }
}

const deserialize = (el, markAttributes = {}) => {
    if (el.nodeType === Node.TEXT_NODE) return jsx('text', markAttributes, el.textContent);
    else if (el.nodeType !== Node.ELEMENT_NODE) return null;

    const attrs = { ...markAttributes };
    // define attributes for text nodes
    switch (el.nodeName) {
        case 'STRONG':
            attrs.bold = true;
            break;
        case 'CODE':
            attrs.code = true;
            break;
        case 'EM':
            attrs.italic = true;
            break;
        case 'U':
            attrs.underline = true;
            break;
        default:
    }

    const children = Array.from(el.childNodes)
        .map(node => deserialize(node, attrs))
        .flat();

    if (children.length === 0) children.push(jsx('text', attrs, ''));
    Array.from(el.attributes).forEach(at => {
        attrs[at.name] = el.attributes[at.name].nodeValue;
    });
    switch (el.nodeName) {
        case 'BODY':
            if (!!children && children.length === 1 && !children[0].type) {
                attrs.type = 'paragraph';
                return [jsx('element', attrs, children)];
            }
            return jsx('fragment', {}, children);
        case 'BLOCKQUOTE':
            attrs.type = 'block-quote';
            return jsx('element', attrs, children);
        case "UL":
            attrs.type = 'bulleted-list';
            return jsx('element', attrs, children);
        case "H1":
        case "H2":
        case "H3":
        case "H4":
        case "H5":
        case "H6":
            attrs.type = el.nodeName.toLowerCase();
            return jsx('element', attrs, children);
        case "LI":
            attrs.type = 'list-item';
            return jsx('element', attrs, children);
        case "OL":
            attrs.type = 'numbered-list';
            return jsx('element', attrs, children);
        case "IMG":
            attrs.type = 'image';
            return jsx('element', attrs, children);
        // case "video":
        //   return <VideoElement {...props} />;
        case 'A':
            attrs.type = 'link';
            attrs.href = el.getAttribute('href');
            return jsx('element', attrs, children);
        case "TABLE":
            attrs.type = 'table';
            return jsx('element', attrs, children);
        case "TBODY":
            attrs.type = 'table-body';
            return jsx('element', attrs, children);
        case "TR":
            attrs.type = 'table-row';
            return jsx('element', attrs, children);
        case "TH":
            attrs.type = 'table-head';
            return jsx('element', attrs, children);
        case "TD":
            attrs.type = 'table-cell';
            return jsx('element', attrs, children);
        case 'BR':
            return '\n'
        case 'P':
            attrs.type = 'paragraph';
            return jsx('element', attrs, children);
        default:
            if (attrs.type) {
                return jsx('element', attrs, children);
            }
            return children
    }
}

export const render = (html) => { 
    return render_deserialized(parse_deserialize(html));
}

export const parse_deserialize = (html) => {
    const document = new DOMParser().parseFromString(html, 'text/html');
    return deserialize(document.body);
}
const render_deserialized = (elements = []) => {
    return elements.map((e, i) => {
        if (e.children) {
            return <Element key={i} element={e}>
                {render_deserialized(e.children)}
            </Element>
        }
        return <Leaf key={i} leaf={e}>
            {e.text}
        </Leaf>
    })
}


const escapeHTML = str =>
    str.replace(
        /[&<>'"]/g,
        tag => ({ '&': '&amp;', '<': '&lt;', '>': '&gt;', "'": '&#39;', '"': '&quot;' }[tag] || tag)
    );
// const unescapeHTML = str =>
//     str.replace(
//         /&amp;|&lt;|&gt;|&#39;|&quot;/g,
//         tag =>
//         ({
//             '&amp;': '&',
//             '&lt;': '<',
//             '&gt;': '>',
//             '&#39;': "'",
//             '&quot;': '"'
//         }[tag] || tag)
//     );
