import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Hide } from '../../utils/hide';
import { NumberLine } from '../charts';
import { QuestionResource } from '../../../Resources';
import { ErrorBoundary } from '../../utils/error_boundary';

export const QuestionLoader = ({ id }) => {
    const [question, setQuestion] = useState();
    const [answer, setAnswer] = useState(false);
    const update = (q) => {
        let qr = { ...question, ...q };
        qr.responses = qr.responses.map((v) => {
            let opt = qr.options.find(ov => ov.content === v.content);
            v.correct = (opt && opt.correct) ? true : false;
            return v
        })
        setQuestion(qr);
    }
    useEffect(() => {
        QuestionResource.get(id, (r) => {
            if (r.status) return setQuestion(r.body);
        });
        // eslint-disable-next-line
    }, []);
    return <>
        <Question question={question} attempt={question} update={update} />
        <div className='mt-2'>
            <Hide state={!answer}>
                <QuestionAnswers question={question} />
            </Hide>
            <Hide state={answer}>
                <button className='btn btn-success' onClick={() => setAnswer(true)}>Show Answer</button>
            </Hide>
        </div>
    </>
}

export const Question = ({ question = {}, attempt = {}, update = () => { } }) => {
    //opbject of response with key for easy update
    let resps = attempt.responses?.reduce((prv, resp) => ({ ...prv, [resp.tag]: resp }), {}) || {};
    let syncTyped = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        resps = { ...resps, [name]: { tag: name, content: value, attempt_question_id: attempt.id } };
        attempt.responses = Object.values(resps).reduce((prv, resp) => prv.concat(resp), []);
        update(attempt);
    }
    let syncChecked = (e) => {
        const tag = e.target.value;
        const content = e.target.getAttribute("data-content");
        switch (question.type) {
            case "single":
                //remove previous checks
                question.options.forEach(opt => {
                    delete resps[opt.id];
                });
                resps = { ...resps, [tag]: { tag: tag, content: content, attempt_question_id: attempt.id } }
                attempt.responses = Object.values(resps).reduce((prv, resp) => prv.concat(resp), []);
                update(attempt)
                break;
            case "multi":
                //if its checked add else remove
                if (e.target.checked) {
                    resps = { ...resps, [tag]: { tag: tag, content: content, attempt_question_id: attempt.id } }
                    attempt.responses = Object.values(resps).reduce((prv, resp) => prv.concat(resp), []);
                    update(attempt)
                    return;
                }
                //remove the current one and set state
                delete resps[tag];
                attempt.responses = Object.values(resps).reduce((prv, resp) => prv.concat(resp), []);
                update(attempt)
                break;
            default:
                break;
        }
    }

    //inline input tracker
    let cnt = 0;
    let opt = {
        replace: domNode => {
            if (domNode.attribs) {
                let tag = domNode.attribs.tag || "";
                //input tag props
                let key = domNode.attribs.key || `${tag}_${cnt++}`;
                let correct = "";
                //chart tag props
                let type = domNode.attribs.type || "";
                let labels = (domNode.attribs.labels || "").split(",");
                switch (tag) {
                    case "short":
                        if (resps[key]?.hasOwnProperty('correct')) {
                            correct = resps[key].correct ? "border border-success border-4" : "border border-danger border-4"
                        }
                        return <input type="text" name={key} onChange={syncTyped} value={resps[key]?.content || ""} className={`form-control-sm form-control w-auto d-inline-block ${correct}`} />;
                    case "radio":
                        if (resps[key]?.hasOwnProperty('correct')) {
                            correct = resps[key].correct ? "border border-success border-4" : "border border-danger border-4"
                        }
                        return <input type="radio" name={key} onChange={syncTyped} value={domNode.attribs?.value || ""} checked={resps[key]?.content === domNode.attribs?.value} className={correct} />;
                    case "long":
                        if (resps[key]?.hasOwnProperty('correct')) {
                            correct = resps[key].correct ? "border border-success border-4" : "border border-danger border-4"
                        }
                        return <textarea name={key} onChange={syncTyped} value={resps[key]?.content || ""} className={`form-control ${correct}`}></textarea>
                    case "chart":
                        switch (type) {
                            case "number_line":
                                return <NumberLine labels={labels} />
                            default:
                                return null;
                        }
                    default:
                        return null;
                }
            }
        }
    };
    let processOptions = (q) => {
        let key = `${q.type}_${cnt++}`;
        let correct = '';
        switch (q.type) {
            case "single":
                return q.options.map((v, i) => {
                    return <div key={i} className="form-check">
                        <input type="radio" onChange={syncChecked} checked={resps.hasOwnProperty(v.id)} data-content={v.conntent} value={v.id} name={v.question_id} id={`option_${v.id}`} className="form-check-input" />
                        <label className="form-check-label" htmlFor={`option_${v.id}`}>{v.content}</label>
                    </div>
                });
            case "multi":
                return q.options.map((v, i) => {
                    return <div key={i} className="form-check">
                        <input type="checkbox" onChange={syncChecked} checked={resps.hasOwnProperty(v.id)} data-content={v.conntent} value={v.id} name={v.id} id={`option_${v.id}`} className="form-check-input" />
                        <label className="form-check-label" htmlFor={`option_${v.id}`}>{v.content}</label>
                    </div>
                });
            case "long":
                if (resps[key]?.hasOwnProperty('correct')) {
                    correct = resps[key].correct ? "border border-success border-4" : "border border-danger border-4"
                }
                return <textarea name={key} onChange={syncTyped} value={resps[key]?.content || ""} placeholder="Answer..." className={`form-control ${correct}`} autoComplete="off"></textarea>
            case "short":
                if (resps[key]?.hasOwnProperty('correct')) {
                    correct = resps[key].correct ? "border border-success border-4" : "border border-danger border-4"
                }
                return <input type="text" name={key} onChange={syncTyped} value={resps[key]?.content || ""} placeholder="Answer..." className={`form-control w-auto ${correct}`} autoComplete="off" />;
            default:
        }
    }
    let processQuestion = (q) => {
        if (!q) return
        let content = `${q.content}`;
        content = content.replaceAll("\n", "<br />");

        /**
         * replace the input tags
         * {{short-key:nmae|value:nmae}}
         * {{chart-type:number_line|labels:23,45,67,34,}}
         * */
        // let inlineRex = /(?:{{(?<tag>short|long|check|radio)-?(?:key:(?<key>[\w]*)\|?)?\|?(?:value:(?<value>[\w]*)\|?)?}})/gm
        let inlineRex = /(?:{{(?<tag>short|long|check|radio|chart)-?(?:\|?key:(?<key>[\w]*)\|?)?(?:\|?value:(?<value>[\w]*)\|?)?(?:\|?type:(?<type>[\w]*)\|?)?(?:\|?labels:(?<labels>[^|}]*)\|?)?}})/g
        //build the span tag
        for (let match of content.matchAll(inlineRex)) {
            const prop = Object.keys(match.groups).map(g =>
                (!!match.groups[g]) ? `${g}="${match.groups[g]}"` : "")
                .join(" ");
            content = content.replace(match[0], `<span ${prop}></span>`);
        }

        content = parse(content, opt);
        return <>{content}{processOptions(q)}</>;
    };
    return (<ErrorBoundary>{processQuestion(question)}</ErrorBoundary>);
}

export const QuestionAnswers = ({ question = {} }) => {
    switch (question.type) {
        case "inline":
        case "short":
        case "long":
            return <>
                {question.options.map((op, i) => (
                    <div key={i} className="input-group my-2">
                        <span className="input-group-text">Answer:</span>
                        <input value={op.content} disabled={true} type="text" className="form-control" placeholder="Option" />
                        <span className="input-group-text">Points:</span>
                        <input value={op.point} disabled={true} type="number" className="form-control" style={{ maxWidth: "60px" }} />
                    </div>
                ))}
            </>
        case "single":
        case "multi":
            return <>
                {question.options.filter(v => v.correct).map((op, i) => (
                    <div key={i} className="input-group my-2">
                        <span className="input-group-text">Answer:</span>
                        <input value={op.content} disabled={true} type="text" className="form-control" placeholder="Option" />
                        <span className="input-group-text">Points:</span>
                        <input value={op.point} disabled={true} type="number" className="form-control" style={{ maxWidth: "60px" }} />
                    </div>
                ))}
            </>
        default:
            return
    }
}

export const Options = ({ ky = "", content = "", points = "", correct = false, disabled = false, onChange = () => { }, dropOpt = () => { } }) => {
    return (
        <div className="input-group mb-2">
            <Hide state={disabled}>
                <button onClick={() => dropOpt(ky)} className="btn btn-danger" type="button">
                    <i className="fas fa-times"></i>
                </button>
            </Hide>
            <span className="input-group-text">Opt.:</span>
            <input value={content} onChange={(e) => onChange(e, ky)} disabled={disabled} name="option_content" type="text" className="form-control" placeholder="Option" />
            <span className="input-group-text">Points:</span>
            <input value={points} onChange={(e) => onChange(e, ky)} disabled={disabled} name="option_point" type="number" className="form-control" style={{ maxWidth: "60px" }} />
            <div className="input-group-text">
                <div className="form-check">
                    <input checked={correct} onChange={(e) => onChange(e, ky)} disabled={disabled} name="option_correct" type="checkbox" className="form-check-input" id={`option_correct_${ky}`} />
                    <label className="form-check-label" htmlFor={`option_correct_${ky}`}>correct</label>
                </div>
            </div>
        </div>);
}
export const AnswerOption = ({ content = "", points = "", disabled = false, onChange = () => { } }) => {
    return (
        <div className="input-group mb-2">
            <span className="input-group-text">Answer:</span>
            <input value={content} onChange={(e) => onChange(e, 0)} disabled={disabled} name="ans_option_content" type="text" className="form-control" placeholder="Option" />
            <span className="input-group-text">Points:</span>
            <input value={points} onChange={(e) => onChange(e, 0)} disabled={disabled} name="ans_option_point" type="number" className="form-control" style={{ maxWidth: "60px" }} />
        </div>);
}