import { Config } from "./config";

export const WidgetResource = {
    isAuthenticated: false,
    base: "/api/custom/school/widget",
    get(callback) {
        Config.Connection()
            .get(this.base)
            .then(r => callback(r.data))
            .catch(e => callback(e.response?.data));
    },
    student(callback) {
        Config.Connection()
            .get(`${this.base}/student`)
            .then(r => callback(r.data))
            .catch(e => callback(e.response?.data));
    },
    teacher(callback) {
        Config.Connection()
            .get(`${this.base}/teacher`)
            .then(r => callback(r.data))
            .catch(e => callback(e.response?.data));
    }
};
export default WidgetResource