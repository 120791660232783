import { Route } from "react-router-dom"
import CustomNav from "../components/customization/nav"
import { QTCreate, QTEdit, QTList, QTView } from "../components/customization/question-template";
import { TopicCreate, TopicEdit, TopicList, TopicView } from "../components/customization/topic";
import { ClassList, ClassView, ClassCreate, ClassEdit } from "../components/customization/class";
import Error404 from "../components/errors/404";

const CustomRoutes = [
    <Route path=":token/goto/*" element={<CustomNav />} key="token" />,
    <Route path='question-template' key="create">
        <Route index element={<QTList />} />
        <Route path=':id' element={<QTView />} />
        <Route path='create' element={<QTCreate />} />
        <Route path='edit/:id' element={<QTEdit />} />
    </Route>,
    <Route path='topic' key="topic">
        <Route index element={<TopicList />} />
        <Route path=':id' element={<TopicView />} />
        <Route path='create' element={<TopicCreate />} />
        <Route path='edit/:id' element={<TopicEdit />} />
    </Route>,
    <Route path='class' key="class">
        <Route index element={<ClassList />} />
        <Route path=':id' element={<ClassView />} />
        <Route path='create' element={<ClassCreate />} />
        <Route path='edit/:id' element={<ClassEdit />} />
    </Route>,
    <Route path="*" element={<Error404 />} key="404" />
];
export default CustomRoutes;