import { Editor } from "../../slate";
import { Actions } from "../../utils/actions";
import { ShowAlert } from "../../utils/alerts";
import { Hide } from "../../utils/hide";
import { AnswerOption, Options } from "../question/util";

export const QForm = ({ data, topics = [], types = [], actions = {}, errors, dropOpt, addOpt, editorValue, setEditorValue = () => { }, onChange }) => {
    const disabled = onChange == null;
    const optAnswers = () => ["inline", "multi", "single"].includes(data.type);
    const shortAnswer = () => ["short"].includes(data.type);
    return <div className="row">
        <div className="col-12 mb-2">
            <ShowAlert errors={errors} tag="error" />
            <div className="row">
                <div className="col-12 col-sm-6 col-xl-4">
                    <label className="col-form-label w-100" >
                        <strong>Target</strong>
                        <select value={data.topic_id ?? ""} onChange={onChange} disabled={disabled} name="topic_id" className="form-select" >
                            <option value="" >select target</option>
                            {topics.map((v, i) => <option key={i} value={v.id} >{v.title}</option>)}
                        </select>
                        <ShowAlert errors={errors} tag="topic_id_error" />
                    </label>
                </div>
                <div className="col-12 col-sm-6 col-xl-4">
                    <label className="col-form-label w-100" >
                        <strong>Type</strong>
                        <select value={data.type ?? ""} onChange={onChange} disabled={disabled} name="type" className="form-select">
                            <option value="" >select question type</option>
                            {types.map((v, i) => <option key={i} value={v} >{v}</option>)}
                        </select>
                    </label>
                    <ShowAlert errors={errors} tag="type_error" />
                </div>
                <div className="col-12 mb-2">
                    <label className="form-label w-100" >
                        <strong>Question</strong>
                    </label>
                    <Hide state={!!editorValue}>
                        <Editor setValue={setEditorValue} />
                    </Hide>
                    <Hide state={!editorValue}>
                        <Editor initialValue={editorValue} setValue={setEditorValue} />
                    </Hide>
                    {/* <textarea value={data?.content ?? ""} onChange={onChange} name="content" className="form-control"></textarea> */}
                    <ShowAlert errors={errors} tag="content_error" />
                </div>
                <div className="col-12">
                    <Hide state={!optAnswers()}>
                        <label className="form-label w-100" >
                            <strong>Options:</strong>
                        </label>
                        {data.options?.map((v, i) => <Options key={i} ky={i} content={v.content} points={v.point} correct={v.correct} disabled={disabled} onChange={onChange} dropOpt={dropOpt} />)}
                    </Hide>
                    <Hide state={!shortAnswer()}>
                        {data.options?.map((v, i) => <AnswerOption key={i} content={v.content} points={v.point} disabled={disabled} onChange={onChange} />)}
                    </Hide>
                    <ShowAlert errors={errors} tag="option_error" />
                </div>
            </div>
            <Hide state={!optAnswers()}>
                <button onClick={addOpt} className="btn btn-secondary btn-sm border rounded">
                    <i className="fas fa-plus"></i>Add Option
                </button>
            </Hide>
            <div className="text-end">
                <Actions mod="gss_question_template" actions={actions} />
            </div>
        </div>
    </div>;
} 