import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { ClassResource, ProfileResource } from "../../Resources";
import { TitledContent } from "../layout/titled-content";
import { ShowAlert } from "../utils/alerts";

export const NewStudent = () => {
    const param = useParams()
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const [errors, setErrors] = useState({});
    const [topicClass, setClass] = useState(null);
    let dPassword = null;

    useEffect(() => {
        ClassResource.get(param.classID, (resp) => {
            if (resp?.status) {
                setClass(resp?.body);
            }
        });
    }, [param.classID])


    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInputs(values => ({ ...values, [name]: value }));
        switch (name) {
            case "fname":
                setInputs(values => ({ ...values, pword: value + (inputs.lname ? inputs.lname[0] : "") }));
                break;
            case "lname":
                setInputs(values => ({ ...values, pword: (inputs.fname || "") + (value ? value[0] : "") }));
                break;
            default:
        }

        dPassword.value = inputs.pword
        //clear the error field
        setErrors(values => ({ ...values, [name + "_error"]: null }));
        // setErrors(values => ({ ...values, error: null }));
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setErrors(null);
        inputs.class_id = param.classID;
        inputs.pword = dPassword.value;
        ProfileResource.create(inputs, resp => {
            if (resp?.status) {
                //navigate to index of all students in this class
                navigate(`/students/${param.classID}`);
            } else {
                setErrors(resp?.body);
            }
        });
    }

    return (
        <TitledContent title={`Add Student to ${topicClass?.name}`}>
            <div className="row mb-3">
                <div className="col-12">
                    <div className="card shadow mb-3">
                        <div className="card-header py-3">
                            <p className="text-primary m-0 fw-bold">By new account</p>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-12">
                                        <ShowAlert errors={errors} tag="error" type="danger" />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label className="form-label">First name:</label>
                                        <input type="text" className="form-control" name="fname" onChange={handleChange} />
                                        <ShowAlert errors={errors} tag="fname_error" type="danger" />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label className="form-label">Last name:</label>
                                        <input type="text" className="form-control" name="lname" onChange={handleChange} />
                                        <ShowAlert errors={errors} tag="lname_error" type="danger" />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label className="form-label">Student&#39;s username:</label>
                                        <input type="text" className="form-control" name="uname" onChange={handleChange} />
                                        <ShowAlert errors={errors} tag="uname_error" type="danger" />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label className="form-label">Default Password </label>
                                        <input ref={el => dPassword = el} type="text" className="form-control" name="pword" onChange={handleChange} />
                                        <ShowAlert errors={errors} tag="pword_error" type="danger" />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label className="form-label">Parent/Guardian&#39;s email </label>
                                        <input type="text" className="form-control" name="parent_email" onInput={handleChange} />
                                        <ShowAlert errors={errors} tag="parent_email_error" type="danger" />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <Button variant="primary" className="float-end mt-4" type="submit">Add</Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </TitledContent>);
} 