import axios from "axios";

export const Config = {
    BaseUrl(subdomain) {
        if (!subdomain) subdomain = this.subDomain();
        return `${Config.protocol()}//${subdomain}.${Config.domain()}${Config.port()}`;
    },
    SystemUrl(domain) {
        return `${Config.protocol()}//${domain}${Config.port()}`;
    },
    subDomain: () => process.env?.REACT_APP_SUBDOMAIN ?? window.location.hostname.split('.')[0],
    domain: () => process.env?.REACT_APP_DOMAIN ?? window.location.hostname.split('.').slice(1).join("."),
    port: () => process.env?.REACT_APP_PORT && process.env?.REACT_APP_PORT.length ? `:${process.env.REACT_APP_PORT}` : "",
    protocol: () => process.env?.REACT_APP_PROTOCOL ?? window.location.protocol,
    Connection: () => {
        let ax = axios.create({
            baseURL: Config.BaseUrl(),
            headers: { "User-Token": window.localStorage.token }
        })
        ax.defaults.withCredentials = true;
        return ax
    }
}