import { Config } from "./config";

const ReportResource = {
    base: "/api/custom/school/report",
    topicPerformance(id, callback) {
        Config.Connection()
            .get(`${this.base}/topic-performance/${id}`)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    classPerformance(id, callback) {
        Config.Connection()
            .get(this.classPerformanceUrl(id))
            .then(r => callback(r))
            .catch(err => callback(err.response?.data));
    },
    classPerformanceUrl(id, format) {
        let url = `${Config.BaseUrl()}${this.base}/class-performance`;
        if (format) return `${url}/${id}?format=${format}`;
        return `${url}/${id}`;
    },
};
export default ReportResource;