import { Config } from "../../Resources/config"

export const ShowImage = ({ src = "", domain, preview, errSrc, className = "", style = {}, alt = "" }) => {
    let dSrc = `${process.env.PUBLIC_URL}/user.png`;
    const onError = (e) => {
        e.target.src = errSrc || dSrc
    }
    const url = () => {
        if (!src || src === "") return dSrc;
        if (!domain) return `${Config.BaseUrl()}/api/${src}`;
        return `${Config.SystemUrl(domain)}/api/${src}`;
    }
    return <img src={preview || url()} onError={onError} className={className} style={style} alt={alt} />
}