import { Config } from "./config";

const QuestionTemplateResource = {
    base: "/api/custom/school/question-template",
    list(criteria, callback) {
        Config.Connection()
            .get(`${this.base}`, { params: criteria })
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    get(id, callback) {
        Config.Connection()
            .get(`${this.base}/${id}`)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    create(data, callback) {
        Config.Connection()
            .post(this.base, data)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    update(data, callback) {
        Config.Connection()
            .put(this.base, data)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    generate(data, callback) {
        Config.Connection()
            .post(`${this.base}/generate`, data)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    delete(data, callback) {
        Config.Connection()
            .delete(`${this.base}/delete`, { data: data })
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    deleteGenerated(data, callback) {
        Config.Connection()
            .delete(`${this.base}/delete-generated`, { data: data })
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    types(callback) {
        Config.Connection()
            .get(`${this.base}/types`)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
};

export default QuestionTemplateResource;