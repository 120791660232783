import { useContext, useEffect, useState } from "react";
import { createContext } from "react";

const FlashAlertContext = createContext({});

const FlashAlertProvider = ({ children }) => {
    const [data, setData] = useState({});
    useEffect(() => {
        //get stord flash message.
        let flash = localStorage.flash;
        if (!flash) return;
        delete localStorage.flash;
        setData(JSON.parse(flash));
    }, []);

    const display = ({ message, type, duration }) => {
        setData({ message: message, type: type, duration: duration });
    };
    const store = ({ message, type, duration }) => {
        localStorage.setItem("flash", JSON.stringify({ message: message, type: type, duration: duration }));
    };
    let value = { data, store, display };
    return <FlashAlertContext.Provider value={value}>{children}</FlashAlertContext.Provider>
}

const useFlashAlert = () => useContext(FlashAlertContext);
const useFlashAlertStore = () => useContext(FlashAlertContext).store;
const useFlashAlertData = () => useContext(FlashAlertContext).data;

export { FlashAlertProvider, useFlashAlert, useFlashAlertStore, useFlashAlertData };