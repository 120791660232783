import { useEffect, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";

export const Pagination = ({ criteria = {}, limit = 3, goto = () => { } }) => {
    const { page, pages } = criteria;
    const [list, setList] = useState([]);
    useEffect(() => {
        const start = Math.max(0, page - (limit - 1)) + 1
        const end = Math.min((pages ?? 0), start + (limit - 1)) + 1
        setList(Array.from(Array(end - start).keys(), (x) => x + start));
        // eslint-disable-next-line
    }, [criteria])

    return <div className="d-flex justify-content-between overflow-auto py-1">
        <span className="text-nowrap d-flex align-items-center">Page {page} of {pages}</span>
        <ButtonGroup>
            <Button variant="outline-secondary" className="border rounded-circle" onClick={() => goto(1)}>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                        <path d="M11 19L4 12L11 5M19 19L12 12L19 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                </span>
            </Button>
            {list.map(v => <Button key={v} onClick={() => goto(v)} variant={v === page ? 'primary' : 'outline-secondary'} className="border rounded-circle">{v}</Button>)}
            <Button variant="outline-secondary" className="border rounded-circle" onClick={() => goto(pages)}>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                        <path d="M13 5L20 12L13 19M5 5L12 12L5 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                </span>
            </Button>
        </ButtonGroup>
    </div>;
}