import { Config } from "./config";

const AttemptQuestionResource = {
    base: "/api/custom/school/attempt-question",
    update(data, callback) {
        Config.Connection()
            .put(this.base, data)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    reponse(data, callback) {
        Config.Connection()
            .put(`${this.base}/response`, data)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
};

export default AttemptQuestionResource;