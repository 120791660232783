import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { AssessmentResource, AttemptResource } from "../../Resources";
import { TitledContent } from "../layout/titled-content";

export const OpenAssessment = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [assessment, setAssessments] = useState({});
    useEffect(() => {
        //get the assessment
        if (params.id) {
            AssessmentResource.open(params.id, resp => {
                if (resp?.status) {
                    setAssessments(resp.body);
                }
            });
        };
        // eslint-disable-next-line
    }, []);
    let startHandler = () => {
        //create/continue an attempt
        AttemptResource.create({ assessment_id: params.id }, resp => {
            //navigate to the assessment
            if (resp?.status) return navigate(`/assessment/attempt/${resp.body.id}`);
        });
    }
    return <TitledContent>
        <div className="row mt-3">
            <div className="col-12 pb-2">
                <h5>{assessment.title}</h5>
                <span className="text-secondary">Topic(s): {assessment.configs?.map(v => v.topic.name).join(", ")}</span>
                <span className="text-secondary d-block mt-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                        <path d="M4 6H20M4 10H20M4 14H20M4 18H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                    {assessment.configs?.reduce((p, c) => p + c.count, 0)} question(s)
                </span>
                <span className="text-secondary d-block">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                        <path d="M12 8V12L15 15M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                    15 minutes
                </span>
                <hr className="my-4" />
                <h5>Instructions</h5>
                <span className="text-secondary d-block mb-2">Please read the following instructions carefully before you proceed.</span>
                <ul>
                    <li>You must complete this assessment in one session — make sure your internet is reliable.</li>
                    <li>If you've previously taken this assessment, your new score will replace your previous score.</li>
                    <li>You must answer all questions before your time runs out in order not to lose points.</li>
                </ul>
                <div className="text-end">
                    <button onClick={startHandler} className="btn btn-primary border rounded-pill" type="button">Start assessment</button>
                </div>
            </div>
        </div>
    </TitledContent>;
}
