import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { TopicResource, ClassResource } from '../../../Resources';
import TitledContent from "../titled-content";
import { CForm } from './form';
import { Actions } from '../../utils/actions';

const View = () => {
    const param = useParams();
    const [topics, setTopics] = useState([]);
    const [data, setData] = useState({});
    const [alert, setAlert] = useState({});
    let navigate = useNavigate();

    useEffect(() => {
        TopicResource.fetch({ parent_id: 1 }, r => {
            if (r.status) {
                setTopics(r.body);
                return
            }
            setAlert(r.body);
        });

        ClassResource.get(param.id, r => {
            if (r?.status) {
                setData(values => ({ ...values, ...r.body }));
                return
            }
            setAlert(r.body);
        });
    }, [param.id]);

    const actions = () => {
        return {
            back: () => navigate("/uilink/class"),
            edit: () => navigate(`/uilink/class/edit/${param.id}`),
        }
    }

    return (
        <TitledContent title={`Class - View`} actions={<Actions mod="gss_class" actions={actions()} />}>
            <CForm data={data} topics={topics} actions={actions()} alerts={alert} />
        </TitledContent >
    );
}
export default View;