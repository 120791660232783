import { useEffect, useState } from "react";
import { NoteForm } from "./form";
import { deserialize, serialize } from "../../slate";
import { TopicNoteResource } from "../../../Resources";


export const NoteEdit = ({ id, page, back = () => { }, callback = () => { } }) => {
    const [data, setData] = useState({});
    const [errors, setErrors] = useState();
    const [editorValue, setEditorValue] = useState();

    useEffect(() => {
        if (!editorValue) return;
        setData(vals => ({ ...vals, note: serialize({ children: editorValue }) }));
    }, [editorValue])

    useEffect(() => {
        TopicNoteResource.get(id, r => {
            if (r.status) {
                setData(r.body);
                setEditorValue(deserialize(r.body?.note ?? ""));
            } else {
                setErrors(r?.body);
            }
        });
        // eslint-disable-next-line
    }, [])

    const onChange = (e) => {
        setErrors();
        const n = e.target.name;
        const v = e.target.value;
        setData(vals => ({ ...vals, [n]: v }));
    }
    const onSubmit = () => {
        setErrors();
        TopicNoteResource.update(data, r => {
            if (r.status) {
                callback();
            } else {
                setErrors(r?.body);
            }
        });
    }
    const actions = () => {
        return {
            back: () => back(),
            refresh: null,
            save: () => onSubmit(),
        }
    }
    return <NoteForm data={data} actions={actions()}
        errors={errors} editorValue={editorValue}
        setEditorValue={setEditorValue}
        onChange={onChange} />
}