import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TopicResource } from "../../../Resources";
import TitledContent from "../titled-content";
import { Actions } from "../../utils/actions";
import { TForm } from "./form";
import { GeneratedQuestions } from "../question";
import { Hide } from "../../utils/hide";
import { Notes } from "../note/list";

const TopicView = () => {
    const param = useParams();
    const [data, setData] = useState({});
    const [topics, setTopics] = useState([]);
    // eslint-disable-next-line
    const [errors, setErrors] = useState();
    const navigate = useNavigate();
    useEffect(() => {
        // eslint-disable-next-line
    }, []);
    //run once
    useEffect(() => {
        //fetch the available topics
        TopicResource.get(param.id, resp => {
            if (resp.status) setData(resp.body);
        });
        //fetch the available topics
        TopicResource.fetch({}, resp => {
            if (resp.status) setTopics(resp.body);
        });
        // eslint-disable-next-line
    }, []);

    const actions = () => {
        return {
            edit: () => navigate(`/uilink/topic/edit/${param.id}`),
            back: () => navigate(`/uilink/topic`),
        }
    }
    return <TitledContent title="Topic/Target" actions={<Actions mod="gss_question" actions={actions()} />}>
        <TForm data={data} topics={topics} errors={errors} actions={actions()} />
        <Hide state={!data.id}>
            <Notes topic={data} />
        </Hide>
        <Hide state={!data.id}>
            <div className="row">
                <div className="col-12">
                    <GeneratedQuestions params={{ topic_id: data.id }} />
                </div>
            </div>
        </Hide>
    </TitledContent >;
}

export default TopicView;