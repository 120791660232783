import { Editor } from "../../slate";
import { Actions } from "../../utils/actions";
import { ShowAlert } from "../../utils/alerts";
import { Hide } from "../../utils/hide";

export const NoteForm = ({ data, actions = {}, errors, editorValue, setEditorValue = () => { }, onChange }) => {
    // const disabled = onChange == null;
    // const mode = ["text", "image", "video"];
    return <div className="row">
        <div className="col-12 mb-2">
            <div className="row">
                {/* <div className="col-12 col-sm-6 col-xl-4">
                    <label className="col-form-label w-100" >
                        <strong>Title</strong>
                        <input value={data?.title ?? ""} onChange={onChange} name="title" type="text" className="form-control" />
                        <ShowAlert errors={errors} tag="title_error" />
                    </label>
                </div> */}
                {/* <div className="col-12 col-sm-6 col-xl-4">
                    <label className="col-form-label w-100" >
                        <strong>Mode</strong>
                        <select value={data.mode ?? ""} onChange={onChange} disabled={disabled} name="mode" className="form-select">
                            <option value="" >select mode</option>
                            {mode.map((v, i) => <option key={i} value={v} >{v}</option>)}
                        </select>
                    </label>
                    <ShowAlert errors={errors} tag="mode_error" />
                </div> */}
                <div className="col-12 mb-2">
                    <label className="form-label w-100">
                        <strong>Note</strong>
                    </label>
                    <Hide state={!!editorValue}>
                        <Editor setValue={setEditorValue} />
                    </Hide>
                    <Hide state={!editorValue}>
                        <Editor initialValue={editorValue} setValue={setEditorValue} />
                    </Hide>
                    <ShowAlert errors={errors} tag="note_error" />
                    <ShowAlert errors={errors} tag="topic_id_error" />
                    <ShowAlert errors={errors} tag="page_error" />
                    <ShowAlert errors={errors} tag="error" />
                </div>
            </div>
            <div className="text-end">
                <Actions mod="gss_topic_note" actions={actions} />
            </div>
        </div>
    </div>;
}