import { PopOver } from "../utils/popover";

export const InitPractice = ({ show = false, setPractice = () => { }, startPractice = () => { } }) => {
    if (!show) return <></>;
    return <PopOver title={`Practice Test`} show={show} close={() => { setPractice(false) }} buttons={[
        { text: "Yes", style: "success", callback: (c) => startPractice({ callback: c }) },
        { text: "No", style: "danger", callback: (c) => c() }
    ]}>
        <p className="text-center">Do you want to practice?</p>
    </PopOver>
};