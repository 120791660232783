const TitledContent = ({ title = "", actions, children }) => {
    document.title = title;
    return <div className="container-fluid pt-3">
        <div className="d-sm-flex justify-content-between align-items-center mb-4">
            <h3 className="text-dark mb-0">{title}</h3>
            <div> {actions} </div>
        </div>
        {children}
    </div>;
}
export default TitledContent;