import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TopicResource, QuestionTemplateResource } from "../../../Resources";
import TitledContent from "../titled-content";
import { deserialize, serialize } from "../../slate";
import { Actions } from "../../utils/actions"; 
import { QTForm } from "./form";

const QTEdit = () => {
    const navigate = useNavigate();
    const param = useParams();
    const [data, setData] = useState({});
    const [topics, setTopics] = useState([]);
    const [types, setTypes] = useState([]);
    const [errors, setErrors] = useState();
    const [editorValue, setEditorValue] = useState();
    useEffect(() => {
        if (!editorValue) return;
        setData(values => ({ ...values, content: serialize({ children: editorValue }) }));
    }, [editorValue])

    //run once
    useEffect(() => {//fetch the questions
        QuestionTemplateResource.get(param.id, (resp) => {
            if (resp.status) {
                setEditorValue(deserialize(resp.body?.content || ""));
                setData(resp.body);
            };
        });
        //fetch the available topics
        TopicResource.fetch({}, resp => {
            if (resp.status) setTopics(resp.body);
        });
        QuestionTemplateResource.types((resp) => {
            if (resp.status) setTypes(resp.body);
        });
        // eslint-disable-next-line
    }, []);

    const addOpt = () => {
        if (!data.options) data.options = [];
        data.options.push({ content: "", point: "1" });
        setData({ ...data });
    }
    const dropOpt = (key) => {
        if (data.options.length < 2) return;
        data.options.splice(key, 1);
        setData({ ...data });
    } 
    const handleInput = (e, key) => {
        setErrors(null);
        const n = e.target.name;
        const v = e.target.value;
        switch (n) {
            case "content":
            case "title":
            case "topic_id":
            case "type":
                setData(values => ({ ...values, [n]: v }));
                //set the write number of options
                switch (v) {
                    case "short":
                        if (data.options.length > 1) {
                            //trim it to the first one and make it the correct answer
                            data.options = data.options.slice(0, 1)
                        }
                        //if no option, set one
                        if (!data.options || data.options?.length === 0) {
                            data.options = [{ content: "", point: "1" }];
                        }
                        data[n] = v
                        data.options[0].correct = true;
                        setData({ ...data });
                        break;
                    case "inline":
                    case "single":
                    case "multi":
                        //if no option, set one
                        if (!data.options || data.options?.length === 0) {
                            data.options = [{ content: "", point: "1", correct: true }];
                        }
                        data[n] = v
                        setData({ ...data });
                        break;
                    default:
                        break;
                }
                break;
            case "option_content":
            case "ans_option_content":
                data.options[key] = { ...data.options[key], ...{ content: v } };
                setData({ ...data });
                break;
            case "option_point":
            case "ans_option_point":
                data.options[key] = { ...data.options[key], ...{ point: v } };
                setData({ ...data });
                break;
            case "option_correct":
                data.options[key].correct = e.target.checked;
                setData({ ...data });
                break;
            default:
                break;
        }
    }
    const handleSubmit = () => {
        QuestionTemplateResource.update(data, r => {
            if (r.status) {
                //st
                navigate(`/uilink/question-template/${r.body.id}`)
            } else {
                setErrors(r?.body);
            }
        });
    }
    const actions = () => {
        return {
            update: () => handleSubmit(),
            back: () => navigate(`/uilink/question-template/${data.id}`),
        }
    }
    return (
        <TitledContent title="Question Templates" actions={<Actions mod="gss_question_template" actions={actions()} />}>
            <QTForm
                data={data} topics={topics}
                types={types} actions={actions()}
                errors={errors} dropOpt={dropOpt}
                addOpt={addOpt} editorValue={editorValue}
                setEditorValue={setEditorValue}
                onChange={handleInput} />
        </TitledContent >);
}
export default QTEdit;