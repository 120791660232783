import { Editor, Transforms, Element as SlateElement } from "slate";
import { ReactEditor } from "slate-react";
import { Image } from "./tool_bar/image";
import { QuestionElement } from "./tool_bar/question";

const LIST_TYPES = ["numbered-list", "bulleted-list"];
// const TABLE_TYPES = ["table", "table-body", "table-row", "table-head", "table-cell"]; 
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

export const Element = (props) => {
    const { attributes, children, element } = props;
    switch (element.type) {
        case "block-quote":
            return <blockquote {...attributes}>{children}</blockquote>;
        case "bulleted-list":
            return <ul {...attributes}>{children}</ul>;
        case "h1":
            return <h1 {...attributes}>{children}</h1>;
        case "h2":
            return <h2 {...attributes}>{children}</h2>;
        case "h3":
            return <h3 {...attributes}>{children}</h3>;
        case "h4":
            return <h4 {...attributes}>{children}</h4>;
        case "h5":
            return <h5 {...attributes}>{children}</h5>;
        case "h6":
            return <h6 {...attributes}>{children}</h6>;
        case "list-item":
            return <li {...attributes}>{children}</li>;
        case "numbered-list":
            return <ol {...attributes}>{children}</ol>;
        case "link":
            return <a {...attributes} href={element.href} className={element.class}> {children} </a>;
        case "table":
            return <table {...attributes} style={toCssObj(element.style)} className={element.class}>{children}</table >;
        case "table-body":
            return <tbody {...attributes}>{children}</tbody>;
        case "table-row":
            return <tr {...attributes}>{children}</tr>;
        case "table-head":
            return <th {...attributes} style={toCssObj(element.style)}>{children}</th>;
        case "table-cell":
            return <td {...attributes} style={toCssObj(element.style)} className={element.class}>{children}</td>;
        case "break":
            return <br />;
        case "image":
            return <Image src={element.src} alt={element.alt} style={toCssObj(element.style)} attributes={attributes}>
                {children}
            </Image>;
        // case "video":
        //   return <VideoElement {...props} />;
        case "question-loader":
            return <QuestionElement id={element.key} attributes={attributes} >
                {children}
            </QuestionElement>;
        default:
            return <p {...attributes} style={toCssObj(element.style)} className={element.class}>{children}</p>;
    }
};

export const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong {...attributes}>{children}</strong>;
    }

    if (leaf.code) {
        children = <code {...attributes}>{children}</code>;
    }

    if (leaf.italic) {
        children = <em {...attributes}>{children}</em>;
    }

    if (leaf.underline) {
        children = <u {...attributes}>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
};


export const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes(n.type) &&
            !TEXT_ALIGN_TYPES.includes(format),
        split: true,
    })
    let newProperties;
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            align: isActive ? undefined : format,
        }
    } else {
        newProperties = {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        }
    }
    Transforms.setNodes(editor, newProperties)
    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
};

export const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};

export const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);
    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};

export const isBlockActive = (editor, format) => {
    const { selection } = editor;
    const blockType = TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    if (!selection) return false

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n[blockType] === format,
        })
    )
    return !!match
};

//use to determine if currect selection/cursor has an ancestor of the specified format
export const isAncestor = (editor, format) => {
    const match = getAncestor(editor, format)
    return !!match
}
//use to determine if currect selection/cursor has an ancestor of the specified format
export const getAncestor = (editor, format) => {
    const { selection } = editor;
    if (!selection) return null;
    return Editor.above(editor, {
        at: Editor.unhangRange(editor, selection),
        match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            n["type"] === format,
    });
}
export const getNode = (editor) => {
    const { selection } = editor;
    if (!selection) return;
    try {
        return Editor.parent(editor, Editor.unhangRange(editor, selection));
    } catch (error) {
        return;
    }
}

export const removeParentNode = (editor, format) => {
    const { selection } = editor;
    if (!selection) return;
    const match = Editor.above(editor, {
        at: Editor.unhangRange(editor, selection),
        match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            n["type"] === format,
    });
    if (!!match) removeNode(editor, match[0]);
}

export const removeNode = (editor, node) => {
    Transforms.removeNodes(editor, { at: ReactEditor.findPath(editor, node) });
}

export const toCssObj = (str) => {
    return str?.replace(/\n/g, "").replace(/-[a-z]/g, x => x[1].toUpperCase()).split(";").reduce((p, c) => {
        if (!c.length || (c.length && c.indexOf(":") < 0)) return p;
        let d = c.split(":");
        return { ...p, [d[0]]: d[1] };
    }, {})
}