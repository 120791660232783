export const withBehaviour = editor => {
    const { isVoid, isInline } = editor

    editor.isVoid = element => {
        switch (element.type) {
            case "image":
            case "question-loader":
                return true
            default:
                return isVoid(element);
        }
    }
    editor.isInline = element => {
        switch (element.type) {
            case "image":
                return true
            case "question-loader":
                return false
            default:
                return isInline(element);
        }
    }
    return editor
}