import { Config } from "./config";

const PreferenceResource = {
    base: "/api/preference",
    find(data, callback) {
        Config.Connection()
            .post(`${this.base}/find`, data)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    all(id, callback) {
        Config.Connection()
            .get(`${this.base}/${id}`)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    Store(data, callback) {
        Config.Connection()
            .put(this.base, data)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    }
};

export default PreferenceResource;