import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import parse from 'html-react-parser';
import { QuestionTemplateResource } from "../../../Resources";
import { Hide } from "../../utils/hide";
import { AnswerOption, Options } from "../question/util";
import { useFlashAlert } from "../../../contexts/flash-alert";
import { Confirm } from "../../utils/popover";
import TitledContent from "../titled-content";
import { Actions } from "../../utils/actions";
import GeneratedQuestions from "../question/generated";

const QTView = () => {
    const [template, setTemplate] = useState({});
    const [generating, setGenerting] = useState(false);
    const [remove, setRemove] = useState(false);
    const navigate = useNavigate();
    let flash = useFlashAlert();
    const param = useParams();
    useEffect(() => {
        QuestionTemplateResource.get(param.id, (r) => {
            if (r.status) setTemplate(r.body);
        });
        // eslint-disable-next-line
    }, []);

    const generate = () => {
        setGenerting(true);
        QuestionTemplateResource.generate({ "template_id": param.id, "fail_limit": 500 }, (r) => {
            // if (r.status) setQuestions(r.body);
            setTemplate({ ...template });
            setGenerting(false);
        });
    }
    const removeQT = () => {
        QuestionTemplateResource.delete({ "id": template.id }, (r) => {
            if (r.status) {
                flash.display({ message: `"${template.title}" deleted`, type: "success", duration: 5000 });
                navigate(`/uilink/question-template`);
            }
        });
    }
    const deleteGenerate = () => {
        setGenerting(true);
        QuestionTemplateResource.deleteGenerated({ "template_id": param.id }, (r) => {
            // if (r.status) setQuestions(r.body);
            setGenerting(false);
            setTemplate({ ...template });
        });
    }

    const optAnswers = () => ["inline", "multi", "single"].includes(template.type)
    const shortAnswer = () => ["short"].includes(template.type)
    const actions = () => {
        return {
            edit: () => navigate(`/uilink/question-template/edit/${param.id}`),
            delete: () => setRemove(true),
            back: () => navigate(`/uilink/question-template`),
        }
    }
    return (
        <TitledContent title="Question Templates" actions={<Actions mod="gss_question_template" actions={actions()} />}>
            <Confirm show={remove} callback={() => removeQT()} close={() => setRemove(false)}>You are about to delete "{template.title}"</Confirm>
            <div className="row">
                <div className="col-12 mb-2">
                    <div className="card shadow mb-3">
                        <div className="card-header py-3">
                            <p className="text-primary m-0 fw-bold">Template</p>
                        </div>
                        <div className="card-body">
                            <div className="row mb-2">
                                <div className="col-12 col-sm-6 col-xl-4">
                                    <strong>Title : </strong>
                                    <span>{template?.title}</span>
                                </div>
                                <div className="col-12 col-sm-6 col-xl-4">
                                    <strong>Topic : </strong>
                                    <span>{template.topic?.name}</span>
                                </div>
                                <div className="col-12 col-sm-6 col-xl-4">
                                    <strong>Type : </strong>
                                    <span>{template?.type}</span>
                                </div>
                                <div className="col-12">
                                    <label className="col-form-label w-100">
                                        <strong>Template : </strong>
                                        <div>{parse(template?.content || "")}</div>
                                    </label>
                                </div>
                                <div className="col-12">
                                    <Hide state={!optAnswers()}>
                                        <label className="form-label w-100" >
                                            <strong>Options:</strong>
                                        </label>
                                        {template.options?.map((v, i) => <Options key={i} readOnly={true} content={v.content} points={v.point} correct={v.correct} />)}
                                    </Hide>
                                    <Hide state={!shortAnswer()}>
                                        <label className="form-label w-100" >
                                            <strong>Answer:</strong>
                                        </label>
                                        {template.options?.map((v, i) => <AnswerOption key={i} readOnly={true} content={v.content} points={v.point} />)}
                                    </Hide>
                                </div>
                            </div>
                            <div className="text-end">
                                <Actions>
                                    <button onClick={deleteGenerate} disabled={generating} className="btn btn-danger border rounded">Delete Generated</button>
                                    <button onClick={generate} disabled={generating} className="btn btn-primary border rounded">Generate</button>
                                </Actions>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <Hide state={!template.id}>
                            <GeneratedQuestions params={{ template_id: template.id}} />
                        </Hide>
                    </div>
                </div>
            </div>
        </TitledContent >);
}
export default QTView;