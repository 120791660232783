import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useAuth, useProfile, useUgroup } from '../../contexts/AuthContext';
import { ShowImage } from '../utils/image';

export const TopBar = ({ toggleMobile = () => { } }) => {
    const authProv = useAuth();
    const ugroup = useUgroup();
    const profile = useProfile();
    let navigate = useNavigate();

    const logOut = () => {
        authProv.signout(() => {
            navigate("sign-in");
        });
    } //clearing the context


    return <div className="row g-0 border-bottom">
        <div className="col-3 my-auto px-3">
            <button onClick={toggleMobile} className="btn btn-light d-lg-none" type="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                    <path d="M4 6H20M4 12H20M4 18H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
            </button>
        </div>
        <div className="col text-end" >
            <NavDropdown align='start' drop='bottom' flip="false" className="no-arrow link-dark me-2 d-inline-block" title={
                <div className="d-inline-flex align-items-center my-auto">
                    <ShowImage className="rounded-circle img-fluid d-inline-block avatar align-middle ms-1" src={profile?.photo} alt={`${profile?.fname} ${profile?.lname}`} />
                    <div className="d-inline-block my-auto">
                        <b className="text-nowrap text-truncate">{profile?.fname ?? ""} {profile?.lname ?? ""}</b>
                        <small className="form-text d-block">{ugroup?.title}</small>
                    </div>
                </div>
            } >
                <NavDropdown.Item href="/settings">
                    <i className="fas fa-user fa-sm fa-fw me-2 text-gray-400"></i> Profile
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logOut}>
                    <i className="fas fa-sign-out-alt fa-sm fa-fw me-2 text-gray-400"></i>Logout
                </NavDropdown.Item>
            </NavDropdown>
        </div>
    </div>;
}
export default TopBar;