import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useBreadCrumb } from "../../contexts/BreadCrumbContext";
import { AssessmentResource, AttemptQuestionResource, AttemptResource } from "../../Resources";
import { TitledContent } from "../layout/titled-content";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Question, QuestionAnswers } from '../customization/question/util';
import { ShowAlert } from "../utils/alerts";
import { Profile } from "../students/detail";
import { Actions } from "../utils/actions";
import { useAuth } from "../../contexts/AuthContext";
import { Hide } from "../utils/hide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const AttemptResult = () => {
    const breadcrumb = useBreadCrumb();
    const params = useParams();
    const [questions, setQuestions] = useState([]);
    const [attempt, setAttempt] = useState({});
    const [assessment, setAssessment] = useState({});
    const [total, setTotal] = useState(0);
    const [alerts, setAlerts] = useState(null);
    const auth = useAuth();

    useEffect(() => {
        breadcrumb.UpdateCrumbs([{ title: "Assessments", url: "/assessment/" }]);
        //get the assessment
        if (params.id) {
            AttemptResource.get(params.id, resp => {
                if (resp?.status) {
                    setAttempt(resp.body);
                    setTotal(resp.body.questions?.reduce((p, c) => p + c.options.reduce((pi, ci) => pi + ci.point, 0), 0));
                    setQuestions(resp.body.questions);
                    AssessmentResource.open(resp.body.assessment_id, resp => {
                        if (resp?.status) {
                            setAssessment(resp.body);
                            feedback();
                            breadcrumb.UpdateCrumbs([
                                { title: "Assessments", url: "/assessment/" },
                                { title: resp.body.title, url: "" }
                            ]);
                        }
                    });
                }
            });
        };
        // eslint-disable-next-line
    }, []);

    const updateGrade = (e, question) => {
        const v = e.target.value;
        var idx = questions.findIndex(v => v.id === question.id);
        questions[idx] = { ...question, ...{ point: v,  loading: true } };
        setQuestions([...questions]);
        AttemptQuestionResource.update({ ...question, ...{ point: v } }, r => {
            if (r.status) {
                questions[idx] = { ...questions[idx], ...{loading: false } };
                setQuestions([...questions]);
                return
            }
            questions[idx] = { ...questions[idx], ...{ loading: false } };
            setQuestions([...questions]);
        })
    }

    const feedback = () => {
        let rate = (attempt.point / total * 100);
        if (rate === 100) {
            setAlerts({ excellent: "Congratulations!!! You did an excellent job" });
            return
        }
        if (rate > 70 && rate < 100) {
            setAlerts({ good: "Wonderful!!! You are almost there. Just try a few more times." });
            return
        }
        setAlerts({ fair: "You are almost there. Just try a few more times." });
    }
    return (
        <TitledContent title="Performance" actions={<Actions actions={{ refresh: null }} />}>
            <div className="col-12 mb-4">
                <Profile profile={attempt.profile} />
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="text-primary fw-bold m-0">Summary</h6>
                    </div>
                    <div className="card-body">
                        <ShowAlert errors={alerts} tag="excellent" type="success" />
                        <ShowAlert errors={alerts} tag="good" type="success" />
                        <ShowAlert errors={alerts} tag="fair" type="warning" />
                        <h4 className="small fw-bold">Assessment : <span>{assessment.title || ""}</span></h4>
                        <h4 className="small fw-bold">Points<span className="float-end">{attempt.point} of {total}</span></h4>
                        <ProgressBar variant="success" animated now={(attempt.point / total * 100)} label={`${parseInt(attempt.point / total * 100)}%`} />
                    </div>
                </div>
                <div className="card shadow mb-3">
                    <div className="card-header py-3">
                        <p className="text-primary m-0 fw-bold">Questions</p>
                    </div>
                    <div className="card-body">
                        {questions?.map((quest, index) =>
                            <div key={index} className="shadow p-2 mb-2">
                                <div className="row">
                                    <div className="col-12 text-end mt-0">
                                        <div className="d-inline-block float-start bg-success mb-1 text-center" style={{ width: "60px", color: "var(--bs-white)" }}>
                                            <span>{index + 1}</span>
                                        </div>
                                        <div className="d-inline-block mt-0" style={{ maxWidth: "157px" }}>
                                            <div className="input-group input-group-sm">
                                                <span className="input-group-text">
                                                    <Hide state={(quest.loading || false)}>
                                                        <FontAwesomeIcon icon={['fas', "fa-check"]} />
                                                    </Hide>
                                                    <Hide state={!(quest.loading || false)}>
                                                        <FontAwesomeIcon icon={['fas', "fa-spinner"]} spin={true} />
                                                    </Hide>
                                                </span>
                                                <input type="number" min={0} className="form-control" value={quest.point} onChange={(e) => updateGrade(e, quest)} disabled={!auth.checkAccess("gss_attempt_questions", "update")} />
                                                <span className="input-group-text">point(s)</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <Question question={quest} attempt={quest} />
                                        <QuestionAnswers question={quest} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </TitledContent>);
}