import { useEffect, useState } from "react";
import { NoteForm } from "./form";
import { serialize } from "../../slate";
import { TopicNoteResource } from "../../../Resources";


export const NoteCreate = ({ topicID, page, back = () => { }, callback = () => { } }) => {
    const [data, setData] = useState({ topic_id: topicID, page: page });
    const [errors, setErrors] = useState();
    const [editorValue, setEditorValue] = useState();

    useEffect(() => {
        if (!editorValue) return;
        setData(values => ({ ...values, note: serialize({ children: editorValue }) }));
    }, [editorValue])

    const onChange = (e) => {
        setErrors();
        const n = e.target.name;
        const v = e.target.value;
        setData(values => ({ ...values, [n]: v }));
    }
    const onSubmit = () => {
        setErrors();
        TopicNoteResource.create(data, r => {
            if (r.status) return callback();
            setErrors(r?.body);
        });
    }
    const actions = () => {
        return {
            back: () => back(),
            refresh: null,
            save: () => onSubmit(),
        }
    }
    return <NoteForm data={data} actions={actions()}
        errors={errors} editorValue={editorValue}
        setEditorValue={setEditorValue}
        onChange={onChange} />
}