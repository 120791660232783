import { useState } from "react";
import { Hide } from "../../utils/hide";
import { Actions } from "../../utils/actions";
import { NoteEdit } from "./edit";
import { render } from "../../slate/schema";

export const NoteView = ({ data, reload = () => { } }) => {
    const [edit, setEdit] = useState(true);
    const [preview, setPreview] = useState(false);
    const actions = () => ({
        back: null,
        refresh: null,
        edit: () => setEdit(false),
    });
    const callback = () => {
        reload();
        setEdit(true);
    }
    return <>
        <Hide state={edit}>
            <NoteEdit id={data.id} back={() => setEdit(true)} callback={callback} />
        </Hide>
        <Hide state={!edit}>
            <div className="py-3">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-6">
                                <h5>Page {data.page}</h5>
                            </div>
                            <div className="col-6 text-end">
                                <Actions mod="gss_topic_note" actions={actions()}>
                                    <button onClick={() => setPreview(!preview)} className="btn btn-primary">Preview</button>
                                </Actions>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div className="col-12">
                        <Hide state={!preview}>
                            {render(data.note)}
                        </Hide>
                    </div>
                </div>
            </div >
        </Hide >
    </>
}