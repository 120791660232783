import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { useBreadCrumb } from "../../../contexts/BreadCrumbContext";
import { useFlashAlert } from "../../../contexts/flash-alert";
import { TopicResource } from "../../../Resources";
import TopicCard from "../../Home/AnimatedCard";
import { TitledContent } from "../../layout/titled-content";
import { InitPractice } from "../utils";

export const Topic = () => {
    const breadcrumb = useBreadCrumb();
    const params = useParams();
    const flash = useFlashAlert();
    const navigate = useNavigate();
    const [topic, setTopic] = useState(null);
    const [subs, setSubs] = useState([]);
    const [question, setQuestion] = useState(false);
    const [notes, setNotes] = useState([]);
    const [practice, setPractice] = useState(false);
    const [active, setActive] = useState(null);

    useEffect(() => {
        setQuestion([]);
        setNotes([]);
        let crum = [];
        TopicResource.fetch({ group_tag: params.tag }, (resp) => {
            if (resp?.status && resp.body.length) {
                let topic = resp.body[0];
                setTopic(topic);
                crum.push({ title: topic.name, url: "" });
                TopicResource.getSub({ group_tag: topic.group_tag }, (resp) => {
                    if (resp?.status) setSubs(resp.body);
                });
                breadcrumb.UpdateCrumbs(crum);
            }
        });
        // eslint-disable-next-line
    }, [params.tag]);

    const skimPage = (dir) => {
        let index = 0;
        if (dir === "next") {
            index = Math.min((active + 1), (notes.length - 1));
        } else {
            index = Math.max((active - 1), 0);
        }
        setActive(index);
    };

    const startPractice = ({ callback = () => { } }) => {
        TopicResource.practice({ topic_id: params.id }, (resp) => {
            //navigate to the assessment
            if (resp?.status) return navigate(`/assessment/attempt/${resp.body.id}`);
            flash.Display(<strong>{resp?.body['error']}</strong>, "danger", () => {
                callback();
            });
        });
    }
    const renderActions = () => {
        return [<Button key="back" variant="warning" className="ms-2" onClick={() => { navigate(-1); }}><i className="fas fa-arrow-circle-left"></i> Back</Button>];
    }

    return (
        <TitledContent title={topic?.name} actions={renderActions()}>
            <div className="row">
                <div className="col-12">
                    <p className="lead">{notes[active]?.note}</p>
                </div>
                <div className="col-12 text-center mb-5">
                    <button className={`btn btn-secondary float-start ${notes.length < 2 ? "d-none" : ""}`} onClick={() => { skimPage('prev') }} type="button">
                        <i className="fas fa-chevron-left"></i>
                        <span className="d-none d-sm-inline"> Previous</span>
                    </button>
                    <div className="float-start p-2"></div>
                    <button className={`btn btn-secondary float-start ${notes.length < 2 ? "d-none" : ""}`} onClick={() => { skimPage('next') }} type="button">
                        <span className="d-none d-sm-inline-block">Next </span>
                        <i className="fas fa-chevron-right"></i>
                    </button>
                    <h6 className={`d-inline-block mt-5 ${notes.length < 2 ? "d-none" : ""}`}>{Math.min((active + 1), notes.length)} of {notes.length}</h6>
                    <InitPractice show={practice} setPractice={setPractice} startPractice={startPractice} />
                    {question ? <Button variant="primary" className="float-end" onClick={() => setPractice(true)}>Practice</Button> : <></>}
                </div>
                <div className="col-12">
                    <div className="row" data-masonry="{&quot;percentPosition&quot;: true }">
                        {subs.map(t => <TopicCard key={t.id} id={t.group_tag} type="topic" title={t.name} />)}
                    </div>
                </div>
            </div>
        </TitledContent>
    );
}