import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TopicResource } from "../../../Resources";
import TitledContent from "../titled-content";
import { Actions } from "../../utils/actions";

const TopicList = () => {
    const [list, setList] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        TopicResource.fetch({}, (resp) => {
            if (resp.status) setList(resp.body);
        });
        // eslint-disable-next-line
    }, [])

    const actions = () => {
        return {
            back: null,
            create: () => navigate("/uilink/topic/create"),
        }
    }
    const lActions = (id) => {
        return {
            back: null,
            refresh: null,
            view: () => navigate(`/uilink/topic/${id}`),
        }
    }
    return (<TitledContent title="Topic" actions={<Actions mod="gss_topic" actions={actions()} />}>
        <div className="row">
            <div className="col-12 mb-2">
                <div className="card shadow">
                    <div className="card-header py-3">
                        <p className="text-primary m-0 fw-bold">All</p>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                            <table className="table my-0" id="dataTable">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Title</th>
                                        <th>Group Tag</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list.map(t =>
                                        <tr key={t.id}>
                                            <td>{t.name}</td>
                                            <td>{t.title}</td>
                                            <td>{t.group_tag}</td>
                                            <td>
                                                <Actions mod="gss_topic" actions={lActions(t.id)} />
                                            </td>
                                        </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </TitledContent>);
}
export default TopicList;