import { Config } from "./config";

const CoyResource = {
    base: "/api/coy",
    info(callback) {
        Config.Connection()
            .get(`${this.base}/info`)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    activeFeatures(callback) {
        Config.Connection()
            .get(`${this.base}/active-features`)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    get(id, callback) {
        Config.Connection()
            .get(`${this.base}/${id}`)
            .then(r => callback(r.data))
            .catch(e => callback(e.response?.data));
    },
    permits(callback) {
        Config.Connection()
            .get(`/api/user_group/permit`)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    }
};

export default CoyResource;