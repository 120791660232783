import { Button } from "react-bootstrap";

export const ExpandButton = ({ icon, toggle, current }) => {
    return (
        <Button variant="outline-primary" className="btn-sm me-1" active={!current} onClick={e => {
            e.preventDefault(); toggle(!current);
        }} >
            <i className={`fas ${icon}`}></i>
        </Button>
    )
}

export const isUrl = (url) => {
    var regx = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
    // Validate URL
    return regx.test(url)
}