import { useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useBreadCrumb } from "../../contexts/BreadCrumbContext";
import { ProfileResource } from "../../Resources";
import { TitledContent } from "../layout/titled-content";
import { ShowAlert } from "../utils/alerts";

export const ManageStudent = () => {
    const breadcrumb = useBreadCrumb();
    const param = useParams();
    const [inputs, setInputs] = useState({});
    const [errors, setErrors] = useState({});
    const [messages, setMessages] = useState({});
    const [submitting, setSubmitting] = useState(false)

    useEffect(() => {
        breadcrumb.UpdateCrumbs([{ title: "Students", url: "" }]);

        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        ProfileResource.get(param.id, (resp) => {
            if (resp?.status) {
                let data = {}
                data["fname"] = resp.body.profile.fname ?? ""
                data["lname"] = resp.body.profile.lname ?? ""
                data["mname"] = resp.body.profile.mname ?? ""
                data["parent_email"] = resp.body.parent_email ?? ""
                data["uname"] = resp.body.user.uname
                data["pword"] = ""
                //will always be blank since we can decrypt it after encryption
                setInputs(data)
            } else {
                setErrors(resp?.body)
            }
        });
    }, [param.id]);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInputs(values => ({ ...values, [name]: value }));
        setErrors(values => ({ ...values, [name + "_error"]: null }));
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setErrors(null);
        setSubmitting(true);
        ProfileResource.update(inputs, resp => {
            setSubmitting(false);
            if (resp?.status) {
                setMessages({ message: "students's information updated" });
            } else {
                setErrors(resp?.body);
            }
        });
    }

    return (
        <TitledContent title={`${inputs.fname} ${inputs.lname}`}>
            <div className="row mb-3">
                <div className="col-12">
                    <div className="card shadow mb-3">
                        <div className="card-header py-3">
                            <p className="text-primary m-0 fw-bold">Basic Information</p>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-12">
                                        <Alert variant="warning">
                                            Password field should only be filled when you need to change the student's password.
                                        </Alert>
                                        <ShowAlert errors={messages} tag="message" type="success" />
                                        <ShowAlert errors={errors} tag="error" type="danger" />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label className="form-label">First name:</label>
                                        <input type="text" className="form-control" name="fname" onChange={handleChange} value={inputs.fname} />
                                        <ShowAlert errors={errors} tag="fname_error" type="danger" />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label className="form-label">Last name:</label>
                                        <input type="text" className="form-control" name="lname" onChange={handleChange} value={inputs.lname} />
                                        <ShowAlert errors={errors} tag="lname_error" type="danger" />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label className="form-label">Middle name:</label>
                                        <input type="text" className="form-control" name="mname" onChange={handleChange} value={inputs.mname} />
                                        <ShowAlert errors={errors} tag="mname_error" type="danger" />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label className="form-label">Student&#39;s username:</label>
                                        <input type="text" className="form-control" name="uname" disabled value={inputs.uname} />
                                        <ShowAlert errors={errors} tag="uname_error" type="danger" />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label className="form-label">Parent/Guardian&#39;s email </label>
                                        <input type="text" className="form-control" name="parent_email" onInput={handleChange} value={inputs.parent_email} />
                                        <ShowAlert errors={errors} tag="parent_email_error" type="danger" />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label className="form-label">Password</label>
                                        <input type="text" className="form-control" name="pword" onChange={handleChange} value={inputs.pword} />
                                        <ShowAlert errors={errors} tag="pword_error" type="danger" />
                                    </div>
                                    <div className="col-12">
                                        <Button variant="primary" className="float-end mt-4" disabled={submitting} type="submit">update</Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </TitledContent>);
} 