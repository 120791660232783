import { createContext, useState, useEffect, useContext } from "react";
import { AuthResource, ProfileResource, CoyResource, UserGroupResource } from "../Resources";

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(window.localStorage.token || null);
    const [retry, setRetry] = useState(window.localStorage.retry || 0);
    const [profile, setProfile] = useState({});
    const [features, setFeatures] = useState(JSON.parse(window.localStorage.features ?? "[]"));
    const [coy, setCoy] = useState(JSON.parse(window.localStorage.coy ?? "{}"));
    const [ugroup, setUgroup] = useState(JSON.parse(window.localStorage.ugroup ?? "{}"));
    //this only runs once
    useEffect(() => {
        if (!token) return;
        updateEnv();
        // eslint-disable-next-line
    }, [token]);

    const signin = (newUser, callback) => {
        return AuthResource.signin(newUser, (resp) => {
            if (resp?.status) {
                window.localStorage.token = resp.token;
                setToken(resp.token);
                updateEnv();
            }
            callback(resp);
        })
    }
    const signout = (callback) => {
        return AuthResource.signout((r) => {
            if (r?.status) cleanUp();
            callback(r);
        });
    };
    const cleanUp = () => {
        delete window.localStorage.token;
        delete window.localStorage.ugroup;
        delete window.localStorage.features;
        delete window.localStorage.coy;
        delete window.localStorage.retry;
        setToken(null);
        setProfile({});
        setCoy({});
        setFeatures([]);
        setUgroup([]);
    }
    const updateEnv = () => {
        ProfileResource.me((r) => {
            if (!r) {
                if (retry <= 3) {
                    window.localStorage.retry = retry + 1;
                    setRetry(retry + 1);
                    return;
                }
                setRetry(0);
                cleanUp();
                return;
            }
            if (!r.status) return cleanUp();
            if (r.status) return setProfile(r.body);
        });
        CoyResource.activeFeatures(r => {
            if (r.status) {
                setFeatures(r.body);
                window.localStorage.features = JSON.stringify(r.body);
            }
        });
        CoyResource.info((r) => {
            if (r.status) {
                setCoy(r.body);
                window.localStorage.coy = JSON.stringify(r.body);
            }
        })
        UserGroupResource.permits(r => {
            if (r.status) {
                setUgroup(r.body);
                window.localStorage.ugroup = JSON.stringify(r.body);
            }
        });
    }
    const listAccess = (mod) => checkAccess(mod, "list");
    const checkAccess = (mod, action) => {
        if (ugroup.super_group) return true;//allow if its a super user
        const module = ugroup.permits?.filter(p => p.mod === mod)[0] || {};
        let allowed = module.actions?.split(",").reduce((p, c) => { return { ...p, [c]: true } }, {}) || {};
        //return true if there is a list permission
        return allowed[action] || false
    }
    const selfOnlyAccess = (mod) => {
        if (ugroup.super_group) return false;
        const module = ugroup.permits?.filter(p => p.mod === mod)[0] || {};
        return module.self || true;
    }
    const value = { token, coy, features, ugroup, profile, updateEnv, checkAccess, listAccess, selfOnlyAccess, signin, signout };
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => useContext(AuthContext);
const useToken = () => useContext(AuthContext).token;
const useCoy = () => useContext(AuthContext).coy;
const useProfile = () => useContext(AuthContext).profile;
const useFeatures = () => useContext(AuthContext).features;
const useUgroup = () => useContext(AuthContext).ugroup;

export { AuthProvider, useAuth, useToken, useCoy, useProfile, useFeatures, useUgroup };