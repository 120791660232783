import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useBreadCrumb } from "../../contexts/BreadCrumbContext";
// import "../../assets/css/Nice-breadcrumb.css";

const Breadcrumb = () => {
    const bcData = useBreadCrumb();
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    useEffect(() => {
        setBreadcrumbs(bcData.crumbs);
    }, [bcData])

    const isActive = (i) => i === (breadcrumbs.length - 1) ? "active" : ``;
    const getIcon = (v) => v.icon ? <i className={v.icon}></i> : ``;

    return (
        <>
            <style>
                {`
                .breadcrumb {
                    --bs-breadcrumb-divider:">";
                }
                .breadcrumb-item > a {
                    text-decoration:none;
                }
                `}
            </style>
            <ol className="breadcrumb">
                {breadcrumbs.map((v, i) => <Link key={i} className={isActive(i) ? "text-dark" : "text-secondary"} href={v.url}>{getIcon(v)} <b>{v.title}</b></Link>)}
            </ol>
            <Alert show={(bcData.message ? true : false)} variant={bcData.variant} onClose={() => bcData.ClearAlert()} dismissible>
                {bcData.message}
            </Alert>
        </>
    );
}

const Link = ({ children, className, href }) => {
    return <li className="breadcrumb-item"><a href={href} className={className ?? ''}>{children}</a></li>
}
export default Breadcrumb
