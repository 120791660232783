import { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { Hide } from "../../utils/hide";
import { isAncestor, removeParentNode } from "../utils"; 
import { Transforms } from "slate";

const CreatTable = ({ editor, format, icon }) => {
    const [minSize] = useState([5, 5]);//minimum draw pad size
    const [size, setSize] = useState(minSize);//draw pad size
    const [hr, setHr] = useState(1);//hover row
    const [hc, setHc] = useState(1);//hover column
    const setHover = (r, c) => {
        let [rows, cols] = size;
        let [minRows, minCols] = minSize;
        [r, c] = (r === 1 && c === 1) ? [2, 2] : [r, c];//ensure a table have at least 2 cells
        setHr(r); setHc(c);
        if (r < rows - 1 && rows > minRows) setSize([rows - 1, size[1]]);//shrink the draw pad
        if (c < cols - 1 && cols > minCols) setSize([size[0], cols - 1]);//shrink the draw pad
        if (r === rows) setSize([size[0] + 1, size[1]]);//add row if we get to the end
        if (c === cols) setSize([size[0], size[1] + 1]);//add column if we get to the edge
    }

    const makeRows = () => {
        let [rows, cols] = size;
        let groups = [];
        let makeCols = (r) => {
            let group = [];
            for (let c = 1; c <= cols; c++) {
                group.push(<Button key={`${r}_${c}`} variant="outline-primary" size='sm'
                    active={r <= hr && c <= hc}
                    onMouseOver={e => setHover(r, c)}
                    onMouseDown={e => {
                        e.preventDefault(); createTable(editor, r, c);
                    }} >
                    <i className={`fas fa-circle`}></i>
                </Button>)
            }
            return group;
        }
        for (let r = 1; r <= rows; r++) {
            groups.push(<ButtonGroup key={`${r}`} className={`d-block`}>{makeCols(r)}</ButtonGroup>);
        }
        return groups;
    }
    return (
        <div >
            {makeRows()}
        </div>
    )
}

const TableActionButton = ({ editor, icon, varient = "primary", add, remove, children }) => {
    return (
        <Button variant={varient} className="me-1" onClick={e => {
            e.preventDefault();
            if (remove) removeParentNode(editor, remove);
        }} >
            <i className={`fas ${icon}`}></i> {children}
        </Button>
    )
}
export const TableOptions = ({ editor }) => {
    isAncestor(editor, "table-cell");
    return <div className='mt-2'>
        <CreatTable editor={editor} />
        <Hide state={!isAncestor(editor, "table")}>
            <div className='mt-2'>
                <TableActionButton editor={editor} icon={"fa fa-plus"}>Cell</TableActionButton>
                <TableActionButton editor={editor} icon={"fa fa-plus"}>Row</TableActionButton>
                <TableActionButton editor={editor} icon={"fa fa-plus"}>Column</TableActionButton>
                <TableActionButton editor={editor} remove="table-cell" icon={"fa fa-minus"} varient="danger">Cell</TableActionButton>
                <TableActionButton editor={editor} remove="table-row" icon={"fa fa-minus"} varient="danger">Row</TableActionButton>
                <TableActionButton editor={editor} icon={"fa fa-minus"} varient="danger">Column</TableActionButton>
                <TableActionButton editor={editor} remove="table" icon={"fa fa-minus"} varient="danger">Table</TableActionButton>
            </div>
        </Hide>
    </div>
}


//table utility
const createTable = (editor, row, column) => {
    let table = { type: "table", class: `table table-bordered`, children: [] };
    const makeBody = (row, column) => {
        let rows = [];
        for (let r = 0; r < row; r++) {
            let row = { type: "table-row", children: [] };
            for (let c = 0; c < column; c++) {//create the table data
                row.children.push({ type: "table-cell", children: [{ text: '' }] });
            }
            rows.push(row);
        }
        return { type: "table-body", children: rows };
    }
    //insert the body
    table.children.push(makeBody(row, column));
    Transforms.insertNodes(editor, table);
    //get the table node
    // const point = Editor.before(editor, Editor.unhangRange(editor, editor.selection));
    // if (!point) return;
    // const match = Editor.above(editor, {
    //     at: point,
    //     match: n =>
    //         !Editor.isEditor(n) &&
    //         SlateElement.isElement(n) &&
    //         n["type"] === "table",
    // });
    // if (!match) return;
    // //make border collapse
    // Transforms.setNodes(editor, { class: `table table-bordered` }, { at: match[1] });
    //give cells a border
    // Array.from(Editor.nodes(editor, {
    //     at: match[1],
    //     match: n =>
    //         !Editor.isEditor(n) &&
    //         SlateElement.isElement(n) &&
    //         TABLE_DATA.includes(n["type"]),
    // })).map(match => Transforms.setNodes(editor,
    //     { style: `border:1px solid #ddd;padding:5px` },
    //     { at: match[1] }),
    // );
} 