import { useEffect, useState } from "react";
import { Actions } from "../../utils/actions";
import { ShowAlert } from "../../utils/alerts";
import { ShowImage } from "../../utils/image";
import { Editor, deserialize, serialize } from "../../slate";
import { Hide } from "../../utils/hide";
import HTMLReactParser from "html-react-parser";
import { useAuth } from "../../../contexts/AuthContext";
import { ClassResource, GeoLocationResource } from "../../../Resources";
import { convertToDateLocalString } from "../../utils/common";

export const CForm = ({ data = {}, topics = [], instructors = [], actions = {}, alerts, noCard = false, editorValue, setEditorValue = () => { }, onChange }) => {
    const auth = useAuth();
    const disabled = onChange == null;
    const [photoState, setPhotoState] = useState(true);
    let photoInput = null;
    const selectPhoto = () => {
        if (disabled) return;
        setPhotoState(false);
        photoInput.click();
        setPhotoState(true);
    }

    return <div className='row'>
        <div className="col-md-4 mb-3">
            <div className="card h-100">
                <div onClick={selectPhoto} className="card-body text-center shadow">
                    <input ref={el => { photoInput = el }} onChange={onChange} type="file" name="photo" hidden={photoState} multiple={false} />
                    <ShowImage className="rounded img-fluid h-100" src={data.photo} preview={data.preview} style={{ objectFit: "contain" }} alt="logo" />
                </div>
            </div>
        </div>
        <div className='col-md-8 mb-3'>
            <ShowAlert alerts={alerts} tag="error" />
            <div className={`card shadow`}>
                <div className={`card-header py-3`}>
                    <p className="text-primary m-0 fw-bold">Class Information</p>
                </div>
                <div className={`card-body`}>
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-3">
                                <label className="form-label">
                                    <strong>Title</strong>
                                </label>
                                <input onChange={onChange} disabled={disabled} type="text" className="form-control" name="name" value={data.name || ""} />
                                <ShowAlert alerts={alerts} tag="name_error" />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-3">
                                <label className="form-label">
                                    <strong>Subject Template</strong>
                                </label>
                                <Hide state={disabled}>
                                    <select onChange={onChange} disabled={disabled} className="form-select" name="topic_id" value={data.topic_id || ""}>
                                        <option defaultValue>Select Subject</option>
                                        {topics?.map((topic) => <option key={topic.id} value={topic.id}>{topic.name}</option>)}
                                    </select>
                                </Hide>
                                <Hide state={!disabled}>
                                    <input onChange={onChange} disabled={disabled} type="text" className="form-control" value={data.topic?.title || ""} />
                                </Hide>
                                <ShowAlert alerts={alerts} tag="topic_id_error" />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-check form-switch">
                                <input onChange={onChange} disabled={disabled} type="checkbox" className="form-check-input" name="restricted" id="restricted" checked={data.restricted || false} />
                                <label className="form-check-label" htmlFor="restricted">{data.restricted ? "Restricted" : "Unrestricted"}
                                    <small className="form-text d-block">When restricted, instructor must invite or add student manually.</small>
                                </label>
                            </div>
                            <div className="form-check form-switch">
                                <input onChange={onChange} disabled={disabled} type="checkbox" className="form-check-input" name="active" id="active" checked={data.active || false} />
                                <label className="form-check-label" htmlFor="active">{data.active ? "Active" : "Dropped"}
                                    <small className="form-text d-block">A class is either Active or Dropped.</small>
                                </label>
                            </div>
                        </div>
                        <Hide state={auth.selfOnlyAccess("gss_class")}>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">
                                        <strong>Instructor</strong>
                                    </label>
                                    <Hide state={disabled}>
                                        <select onChange={onChange} disabled={disabled} className="form-select" name="instructor_id" value={data.instructor_id || ""}>
                                            <option defaultValue>Select Instructor</option>
                                            {instructors?.map((dt) => <option key={dt.id} value={dt.id}>{dt.fname} {dt.lname}</option>)}
                                        </select>
                                    </Hide>
                                    <Hide state={!disabled}>
                                        <input onChange={onChange} disabled={disabled} type="text" className="form-control" value={data.instructor ? (`${data.instructor.fname} ${data.instructor.lname}`) : ""} />
                                    </Hide>
                                    <ShowAlert alerts={alerts} tag="instructor_id_error" />
                                </div>
                            </div>
                        </Hide>
                    </div>
                </div>
            </div>
        </div>
        <Hide state={!data.id}>
            <Schedule class_id={data.id} event={data.event} />
        </Hide>
        <div className='col-12'>
            <ShowAlert alerts={alerts} tag="error" />
            <div className={`card shadow mb-3`}>
                <div className={`card-header py-3`}>
                    <p className="text-primary m-0 fw-bold">Introduction</p>
                </div>
                <div className={`card-body`}>
                    <Hide state={disabled}>
                        <Hide state={!!editorValue}>
                            <Editor setValue={setEditorValue} />
                        </Hide>
                        <Hide state={!editorValue}>
                            <Editor initialValue={editorValue} setValue={setEditorValue} />
                        </Hide>
                    </Hide>
                    <Hide state={!disabled}>
                        {HTMLReactParser(data?.description || "")}
                    </Hide>
                    {/* <textarea value={data?.content ?? ""} onChange={onChange} name="content" className="form-control"></textarea> */}
                    <ShowAlert alerts={alerts} tag="content_error" />
                    <div className="text-end my-2">
                        <Actions mod="gss_class" actions={actions} />
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

const Schedule = ({ class_id, event = {} }) => {
    const [alert, setAlert] = useState({});
    const [show, setShow] = useState(!!event);
    const [TimeZones, setTimeZones] = useState([]);
    const [editorValue, setEditorValue] = useState();
    const [data, setData] = useState({
        ...{
            start_date: (new Date()).toISOString().split("T")[0],
            end_date: (new Date()).toISOString().split("T")[0],
            time_zone: new window.Intl.DateTimeFormat().resolvedOptions().timeZone,
        }, ...event
    });
    const repeatList = ["No", "Daily", "Weekly", "Monthly", "Yearly"];
    const weekdays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    const timeList = [];
    const start = new Date();
    start.setHours(0, 0, 0);
    const end = new Date();
    end.setHours(23, 30, 0);

    while (start <= end) {
        if (timeList.length) {
            timeList.push({ key: (start.getHours() * 60 + start.getMinutes()), value: start.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit' }) });
        } else {
            timeList.push({ key: (start.getHours() * 60 + start.getMinutes()), value: start.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit' }) });
        }
        start.setMinutes(start.getMinutes() + 30);
    }
    useEffect(() => {
        if (!editorValue) return;
        setData(values => ({ ...values, description: serialize({ children: editorValue }) }));
    }, [editorValue]);

    useEffect(() => {
        GeoLocationResource.timezone(r => {
            if (r?.status) {
                return setTimeZones(r.body);
            }
        });
        setEditorValue(deserialize(data.description || ""));
        // eslint-disable-next-line
    }, []);
    const onChange = (e) => {
        const n = e.target.name;
        const v = e.target.value;
        const c = e.target.checked;
        switch (n) {
            case "all_day":
            case "sunday":
            case "monday":
            case "tuesday":
            case "wednesday":
            case "thursday":
            case "friday":
            case "saturday":
                setData(values => ({ ...values, [n]: c }));
                break;
            default:
                setData(values => ({ ...values, [n]: v }))
                break;
        }
        setAlert();
    }
    const AddSchedule = (e) => {
        setAlert();
        ClassResource.addEvent({
            ...data, ...{
                start_date: convertToDateLocalString(data.start_date),
                end_date: convertToDateLocalString(data.end_date),
                repeat_end_date: convertToDateLocalString(data.repeat_end_date),
            }, ...{ class_id: class_id }
        }, r => {
            if (r?.status) {
                return setAlert(values => ({ ...values, added: { type: "success", message: r?.body.msg } }));
            }
            setAlert(r?.body);
        });
    }
    const DropSchedule = (e) => {
        setAlert();
        ClassResource.dropEvent({ ...data, ...{ class_id: class_id } }, r => {
            if (r?.status) {
                setShow(false);
                return setAlert(values => ({ ...values, dropped: { type: "success", message: "Schedule dropped" } }));
            }
            setAlert(r?.body);
        });
    }

    return <div className='col-12'>
        <div className={`card shadow mb-3`}>
            <div className={`card-header py-3`}>
                <div className="d-flex justify-content-between">
                    <span className="text-primary m-0 fw-bold">Schedule</span>
                    <Hide state={show}>
                        <button onClick={() => setShow(true)} className="btn btn-primary btn-sm">Add Schedule</button>
                    </Hide>
                    <Hide state={!show}>
                        <button onClick={() => DropSchedule()} className="btn btn-danger btn-sm">Drop Schedule</button>
                    </Hide>
                </div>
                <ShowAlert alerts={alert} tag="dropped" />
            </div>
            <Hide state={!show}>
                <div className={`card-body`}>
                    <div className="row">
                        <div className="col-12 mb-2">
                            <input onChange={onChange} value={data.title || ""} name="title" type="text" className="form-control" placeholder="Title" />
                        </div>
                        <div className="col-12">
                            <div className="row g-0">
                                <div className="col-12 col-sm-5 mb-2">
                                    <div className="row g-0">
                                        <div className="col-8">
                                            <input onChange={onChange} value={convertToDateLocalString(data.start_date || "")} name="start_date" className="form-control-sm form-control" type="date" />
                                        </div>
                                        <div className="col">
                                            <select onChange={onChange} value={data.start_time || ""} name="start_time" className="form-select-sm form-control">
                                                {timeList.map((v, k) => <option key={k} value={v.key}>{v.value}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 d-none d-sm-block text-center"><span>&lt;-&gt;</span></div>
                                <div className="col-12 col-sm-5 mb-2">
                                    <div className="row g-0">
                                        <div className="col-4">
                                            <select onChange={onChange} value={data.end_time || ""} name="end_time" className="form-select-sm form-control">
                                                {timeList.map((v, k) => <option key={k} value={v.key} disabled={data.end_date === data.start_date ? v.key <= data.start_time : false}>{v.value}</option>)}
                                            </select>
                                        </div>
                                        <Hide state={`${(data.repeat || "0")}` !== "0"}>
                                            <div className="col-8">
                                                <input onChange={onChange} value={convertToDateLocalString(data.end_date || "")} name="end_date" min={data.start_date || ""} className="form-control-sm form-control" type="date" />
                                            </div>
                                        </Hide>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-2">
                            <div className="form-check form-switch form-check-inline">
                                <input onChange={onChange} checked={(data.all_day || false)} name="all_day" className="form-check-input" type="checkbox" id="all_day" />
                                <label className="form-check-label" htmlFor="all_day">All day</label>
                            </div>
                            <select onChange={onChange} value={data.time_zone || ""} name="time_zone" className="d-inline form-control w-auto">
                                {TimeZones.map((v, k) => <option key={k} value={v.key}>{v.value}</option>)}
                            </select>
                        </div>
                        <div className="col-12 mb-2">
                            <div className="input-group d-inline-block w-auto">
                                <select onChange={onChange} value={data.repeat || 0} name="repeat" className="form-select d-inline w-auto">
                                    {repeatList.map((v, k) => <option key={k} value={k}>{v}</option>)}
                                </select>
                                <span className="d-inline-block input-group-text">Repeat</span>
                            </div>
                        </div>
                        <Hide state={`${(data.repeat || "0")}` !== "1"}>
                            <div className="col-12">
                                <h6>Repeat on</h6>
                                {weekdays.map(v => <div key={v} className="form-check form-switch form-check-inline">
                                    <input onChange={onChange} checked={(data[v] || false)} className="form-check-input" type="checkbox" id={v} name={v} />
                                    <label className="form-check-label" htmlFor={v}>{v[0].toUpperCase()}</label>
                                </div>)}
                            </div>
                        </Hide>
                        <Hide state={(data.repeat || "0") === "0"}>
                            <div className="col">
                                <h6>Repeat until</h6>
                                <input onChange={onChange} value={convertToDateLocalString(data.repeat_end_date || "")} name="repeat_end_date" min={data.start_date || ""} className="form-control-sm form-control w-auto" type="date" />
                            </div>
                        </Hide>
                        <div className='col-12'>
                            <h6>Description</h6>
                            <Hide state={!!editorValue}>
                                <Editor setValue={setEditorValue} />
                            </Hide>
                            <Hide state={!editorValue}>
                                <Editor initialValue={editorValue} setValue={setEditorValue} />
                            </Hide>
                        </div>
                        <div className="col-12 text-end mt-2">
                            <ShowAlert alerts={alert} />
                            <button onClick={AddSchedule} className="btn btn-primary btn-sm">Save Schedule</button>
                        </div>
                    </div>
                </div>
            </Hide>
        </div>
    </div>
}