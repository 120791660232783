export const PopIcon = ({ varient = "primary" }) => {
    return <RadialIcon icon="search" varient={varient} />;
}

export const RadialIcon = ({ icon = "", varient = "primary" }) => {
    switch (icon) {
        case "search":
            icon = <svg className={`text-${varient} d-inline-block`} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
            break;

        case "academic-cap":
            icon = <svg className={`text-${varient} d-inline-block`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.26007 10.1472C3.98502 12.2527 3.82112 14.3713 3.76907 16.4942C6.64786 17.6967 9.40423 19.1735 12.0001 20.9042C14.5962 19.1735 17.3529 17.6967 20.2321 16.4942C20.18 14.3713 20.0161 12.2527 19.7411 10.1472M19.7411 10.1472C20.6196 9.85191 21.5059 9.58048 22.3991 9.33316C19.1356 7.04541 15.6521 5.08905 12.0001 3.49316C8.34806 5.08938 4.86447 7.04608 1.60107 9.33416C2.49436 9.58076 3.38069 9.85186 4.25907 10.1472C6.92773 11.0443 9.51701 12.1622 12.0001 13.4892C14.4828 12.1622 17.0728 11.0444 19.7411 10.1472ZM6.75007 15.0002C6.94899 15.0002 7.13975 14.9211 7.2804 14.7805C7.42106 14.6398 7.50007 14.4491 7.50007 14.2502C7.50007 14.0513 7.42106 13.8605 7.2804 13.7198C7.13975 13.5792 6.94899 13.5002 6.75007 13.5002C6.55116 13.5002 6.3604 13.5792 6.21974 13.7198C6.07909 13.8605 6.00007 14.0513 6.00007 14.2502C6.00007 14.4491 6.07909 14.6398 6.21974 14.7805C6.3604 14.9211 6.55116 15.0002 6.75007 15.0002ZM6.75007 15.0002V11.3252C8.44681 10.2705 10.1994 9.30843 12.0001 8.44316M4.99307 19.9932C5.55105 19.4366 5.99351 18.7752 6.29504 18.047C6.59656 17.3189 6.75121 16.5383 6.75007 15.7502V14.2502" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

            break;
        default:
            icon = "??"
            break;
    }
    return <div className="text-center d-inline-block" style={{ width: "30px", height: "30px", position: "relative" }}>
        <div className={`rounded-circle`} style={{ position: "absolute", backgroundImage: `radial-gradient(circle, var(--bs-${varient}) 60%, var(--bs-gray-200) 70%)`, opacity: 0.4, width: "100%", height: "100%" }}></div>
        {icon}
    </div>;
}