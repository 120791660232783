import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClassResource } from "../../../Resources";
import TitledContent from "../titled-content";
import { Actions } from "../../utils/actions";
import { Pagination } from "../utils/paginate";
import { ShowAlert } from "../../utils/alerts";

const List = () => {
    const [items, setItems] = useState([]);
    const [criteria, setCriteria] = useState({ page: 1, page_size: 10 });
    const [alerts, setAlerts] = useState({});
    // const flash = useFlashAlert();
    const navigate = useNavigate();
    useEffect(() => {
        ClassResource.list(criteria, (r) => {
            if (r.status) {
                setItems(r.body.items);
                setCriteria(values => ({ ...values, count: r.body.count }));
                setCriteria(values => ({ ...values, pages: Math.ceil(r.body.count / criteria.page_size) }));
                return
            }
            setAlerts(r.body);
        });
        // eslint-disable-next-line
    }, [criteria.page]);
    useEffect(() => {
        setPage(1);
        // eslint-disable-next-line
    }, [criteria.page_size]);
    const setPage = (page) => setCriteria(values => ({ ...values, page: page }));
    const handleCriteria = (e) => {
        const n = e.target.name;
        const v = e.target.value;
        setCriteria(values => ({ ...values, [n]: v }));
    }
    const actions = () => {
        return {
            back: null,
            create: () => navigate("/uilink/class/create"),
        }
    }
    const lActions = (id) => {
        return {
            back: null,
            refresh: null,
            view: () => navigate(`/uilink/class/${id}`),
        }
    }
    return (<TitledContent title="Class - List" actions={<Actions mod="gss_class" actions={actions()} />}>
        <div className="row">
            <div className="col-12 mb-2">
                <div className="card shadow">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-sm-5 col-md-4 col-lg-3 col-xl-2 text-nowrap">
                                <div className="input-group">
                                    <span className="input-group-text">Show</span>
                                    <select onChange={handleCriteria} value={criteria.page_size} name={"page_size"} className="d-inline-block form-select form-select-sm">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col">
                                <input type="search" className="form-control" placeholder="Search" />
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <ShowAlert alerts={alerts} tag="error" />
                        <div className="table-responsive table mt-2" role="grid" aria-describedby="dataTable_info">
                            <table className="table table-striped table-hover my-0">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Topic</th>
                                        <th>Restricted</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(items || []).map(item =>
                                        <tr key={item.id}>
                                            <td>{item.name}</td>
                                            <td>{item.topic?.name}</td>
                                            <td>{item.restricted ? "Yes" : "No"}</td>
                                            <td>{item.active ? "Active" : "Dropped"}</td>
                                            <td className="text-end">
                                                <Actions mod="gss_class" actions={lActions(item.id)} />
                                            </td>
                                        </tr>)}
                                </tbody>
                            </table>
                        </div>
                        <div className="row">
                            <div className="col-12 text-end">
                                <p>Showing {((criteria.page - 1) * criteria.page_size) + 1} to {((criteria.page - 1) * criteria.page_size) + items.length} of {criteria.count}</p>
                            </div>
                            <div className="col text-end">
                                <Pagination criteria={criteria} goto={setPage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </TitledContent>);
}

export default List;