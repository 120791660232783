import { Config } from "./config";

const NotifiedResource = {
    base: "/api/notified",
    list(criteria, callback) {
        Config.Connection()
            .get(this.base, { params: criteria })
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
    read(data, callback) {
        Config.Connection()
            .post(this.base, data)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response));
    },
    delete(data, callback) {
        Config.Connection()
            .delete(this.base, { data: data })
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    },
};
export default NotifiedResource;