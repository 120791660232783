import { useParams } from "react-router-dom";
import { AssessmentResource, ClassResource, ProfileResource, ReportResource } from "../../Resources";
import { useEffect, useState } from "react";
import { ShowImage } from "../utils/image";
import { Pagination } from "../utils/paginate";
import { ShowAlert } from "../utils/alerts";
import { PopIcon } from "../utils/custom_icons";
import { Hide } from "../utils/hide";
import Breadcrumb from "../layout/Breadcrumb";
import { useBreadCrumb } from "../../contexts/BreadCrumbContext";
import { ViewBtn } from "../assessment/utils";
import { ProgressBar } from "react-bootstrap";

const StudentDetail = () => {
    const param = useParams();
    const breadcrumb = useBreadCrumb();
    const [profile, setProfile] = useState({ id: param.id });
    const [alerts, setAlerts] = useState({});
    const [tab, setTab] = useState("assessment");
    const [stats, setStats] = useState({});
    useEffect(() => {
        ProfileResource.get(param.id, r => {
            if (r.status) {
                breadcrumb.UpdateCrumbs([
                    { title: "Students", url: "/students" },
                    { title: `${r.body.fname} ${r.body.lname}`, url: "" }
                ]);
                setProfile(r.body);
                return
            }
            setAlerts(r?.body);
        });
        AssessmentResource.list({ profile_id: param.id, page_size: 1, my: true }, (r) => {
            if (r.status) setStats({ ...stats, ...{ assessment: r.body.count } });
        });
        // eslint-disable-next-line
    }, [param.id])

    return <div className="row my-2">
        <div className="col my-2">
            <Breadcrumb />
        </div>
        <ShowAlert alerts={alerts} />
        <Profile profile={profile} />
        <div className="col-12 col-md-4 col-lg-4 my-2">
            <div className="card textwhite bg-primary text-white border rounded">
                <div className="card-body">
                    <h6 className="card-title">Lessons</h6>
                    <h2>-</h2>
                </div>
            </div>
        </div>
        <div className="col-12 col-md-4 col-lg-4 my-2">
            <div className="card textwhite bg-success text-white border rounded">
                <div className="card-body">
                    <h6 className="card-title">Hours</h6>
                    <h2>-</h2>
                </div>
            </div>
        </div>
        <div className="col-12 col-md-4 col-lg-4 my-2">
            <div className="card textwhite bg-dark text-white border rounded">
                <div className="card-body">
                    <h6 className="card-title">Assessments</h6>
                    <h2>{stats.assessment}</h2>
                </div>
            </div>
        </div>
        <div className="col-12 my-2">
            <div className="row mt-3">
                <div className="col-12">
                    <div className="border-bottom">
                        <button onClick={() => setTab("assessment")} className={`btn btn-outline-${tab === "assessment" ? "primary" : "secondary"} border rounded-0 border-0 border-bottom ${tab === "assessment" ? "border-primary" : ""}`} type="button">Assessments</button>
                        <button onClick={() => setTab("classes")} className={`btn btn-outline-${tab === "classes" ? "primary" : "secondary"} border rounded-0 border-0 border-bottom ${tab === "classes" ? "border-primary" : ""}`}>Classes</button>
                        <button onClick={() => setTab("performance")} className={`btn btn-outline-${tab === "performance" ? "primary" : "secondary"} border rounded-0 border-0 border-bottom ${tab === "performance" ? "border-primary" : ""}`}>Performance</button>
                    </div>
                </div>
                <div className="col-12 py-3">
                    <div className="card border-light border rounded shadow-sm">
                        <div className="card-body p-0">
                            <Hide state={!(tab === "assessment")}>
                                <AssessmentTable profile={profile} />
                            </Hide>
                            <Hide state={!(tab === "classes")}>
                                <ClassTable profile={profile} />
                            </Hide>
                            <Hide state={!(tab === "performance")}>
                                <Performance profile={profile} />
                            </Hide>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default StudentDetail;


const AssessmentTable = ({ profile }) => {
    const [items, setItems] = useState([]);
    const [alerts, setAlerts] = useState({});
    const [criteria, setCriteria] = useState({ profile_id: profile.id, page: 1, page_size: 5, order_by: "id:true" });
    useEffect(() => {
        AssessmentResource.list({ ...criteria, ...{ my: true } }, (r) => {
            if (r.status) {
                setItems(r.body.items);
                setCriteria(values => ({ ...values, count: r.body.count }));
                setCriteria(values => ({ ...values, pages: Math.ceil(r.body.count / criteria.page_size) }));
                return
            }
            setAlerts(r.body);
        });
        // eslint-disable-next-line
    }, [criteria.page]);
    useEffect(() => {
        setPage(1);
        // eslint-disable-next-line
    }, [criteria.page_size]);
    const setPage = (page) => setCriteria(v => ({ ...v, page: page }));
    const topScore = (attempts) => {
        let val = Math.max(...attempts.map(v => parseInt((v.point / v.total_point * 100))))
        return isNaN(val) || !isFinite(val) ? "--" : val;
    }

    if (!items.length) return <div className="text-center">
        <ShowAlert alerts={alerts} />
        <div className="text-center d-inline-block py-2">
            <PopIcon />
            <h6>No assessments found</h6>
            <p>We couldn't find any assessment taken by {profile.fname} {profile.lname}.</p>
        </div>
    </div>;
    return <>
        <ShowAlert alerts={alerts} />
        <div className="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
            <table className="table my-0" id="dataTable">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Class</th>
                        <th>Teacher</th>
                        <th>Score</th>
                        <th>Date</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {items.map(ass =>
                        <tr key={ass.id} id={ass.id}>
                            <td className='align-middle'>{ass.title}</td>
                            <td className='align-middle'>{ass.class?.name}</td>
                            <td className='align-middle'>{ass.class?.instructor?.fname} {ass.class?.instructor?.lname}</td>
                            <td className='align-middle'>{topScore(ass.attempts)}%</td>
                            <td className='align-middle text-nowrap'>{new Date(ass.updated_at).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}</td>
                            <td className='align-middle text-nowrap'>
                                <ViewBtn assessment={ass} readOnly={true} />
                            </td>
                        </tr>)}
                </tbody>
            </table>
        </div>
        <Pagination criteria={criteria} goto={setPage} />
    </>;
}


const ClassTable = ({ profile }) => {
    const [items, setItems] = useState([]);
    const [alerts, setAlerts] = useState({});
    const [criteria, setCriteria] = useState({ profile_id: profile.id, page: 1, page_size: 5, order_by: "id:true" });
    useEffect(() => {
        ClassResource.list(criteria, (r) => {
            if (r.status) {
                setItems(r.body.items);
                setCriteria(values => ({ ...values, count: r.body.count }));
                setCriteria(values => ({ ...values, pages: Math.ceil(r.body.count / criteria.page_size) }));
                return
            }
            setAlerts(r.body);
        });
        // eslint-disable-next-line
    }, [criteria.page]);
    useEffect(() => {
        setPage(1);
        // eslint-disable-next-line
    }, [criteria.page_size]);
    const setPage = (page) => setCriteria(v => ({ ...v, page: page }));

    if (!items.length) return <div className="text-center">
        <ShowAlert alerts={alerts} />
        <div className="text-center d-inline-block" style={{ maxWidth: "350px" }}>
            <PopIcon varient="success" />
            <h6>No classes found</h6>
            <p>{profile.fname} {profile.lname} is not enrolled in any class.</p>
        </div>
    </div>;
    return <>
        <ShowAlert alerts={alerts} />
        <div className="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
            <table className="table my-0" id="dataTable">
                <thead>
                    <tr>
                        <th>Class</th>
                        <th>Topic</th>
                        <th>Teacher</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map(item =>
                        <tr key={item.id} id={item.id}>
                            <td className='align-middle'>{item.name}</td>
                            <td className='align-middle'>{item.topic?.name}</td>
                            <td>{item.instructor.fname} {item.instructor.lname}</td>
                            <td className='align-middle text-nowrap'>
                                {new Date(item.updated_at).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}
                            </td>
                        </tr>)}
                </tbody>
            </table>
        </div>
        <Pagination criteria={criteria} goto={setPage} />
    </>;
}



const Performance = ({ profile }) => {
    const [items, setItems] = useState([]);
    useEffect(() => {
        ReportResource.topicPerformance(profile.id, (r) => {
            if (r.status) {
                setItems(r.body ?? []);
                return
            }
        });
        // eslint-disable-next-line
    }, []);
    if (!items.length) {
        return <span className="d-block text-center py-5">no perfomance report</span>
    }
    const colorCode = (score) => {
        let code = [
            { min: 0, max: 50, grade: "F" },
            { min: 51, max: 60, grade: "E" },
            { min: 61, max: 70, grade: "D" },
            { min: 71, max: 80, grade: "C" },
            { min: 81, max: 90, grade: "B" },
            { min: 91, max: -1, grade: "A" },
        ];
        switch (code.find(v => score > v.min && (v.max === -1 || score < v.max))) {
            case "A":
            case "B":
                return "success";
            case "C":
                return "warning";
            default:
                return "danger";
        }
    }
    return items.map(v => <div className="d-block">
        <h4 className="small fw-bold"><span>{v.title}</span></h4>
        <ProgressBar variant={colorCode((v.scored / v.total * 100))} now={(v.scored / v.total * 100)} label={`${parseInt(v.scored / v.total * 100)}%`} />
    </div>);
}

export const Profile = ({ profile = {} }) => {
    return <div className="col-12 my-2">
        <div className="card">
            <div className="card-body d-flex align-items-center">
                <div className="d-inline-block me-2" style={{ height: "120px" }}>
                    <ShowImage className="rounded-circle img-fluid h-100" src={profile.photo} style={{ objectFit: "contain" }} alt={`${profile.fname} ${profile.lname}`} />
                </div>
                <div className="d-inline-block">
                    <h5>{profile.fname} {profile.lname}</h5>
                </div>
            </div>
        </div>
    </div>;
}