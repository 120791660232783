import { useEffect, useState } from "react";
import { QuestionResource, TopicResource } from "../../../Resources";
import { Hide } from "../../utils/hide";
import { Pagination } from "../../utils/paginate";
import QCreate from "./create";
import QEdit from "./edit";
import { Question, QuestionAnswers } from "./util";


const GeneratedQuestions = ({ params = {}, picker }) => {//picker must be a callback method
    const [create, setCreate] = useState(true);
    const [items, setItems] = useState([]);
    const [topics, setTopics] = useState([]);
    const [criteria, setCriteria] = useState({ ...{ order_by: "created_at:true", page: 1, page_size: 10 }, ...params });
    useEffect(() => {
        TopicResource.fetch({}, r => {
            if (r.status) setTopics(r.body);
        });
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        loadList();
        // eslint-disable-next-line
    }, [criteria.page, criteria.topic_id]);
    useEffect(() => {
        setPage(1);
        loadList();
        // eslint-disable-next-line
    }, [criteria.page_size]);

    const loadList = () => {
        QuestionResource.list(criteria, (resp) => {
            if (resp.status) {
                setItems(resp.body.items);
                setCriteria(values => ({ ...values, count: resp.body.count }));
                setCriteria(values => ({ ...values, pages: Math.ceil(resp.body.count / criteria.page_size) }));
            }
        });
    };
    const setPage = (page) => setCriteria(values => ({ ...values, page: page }));
    const createCallback = () => {
        setPage(1);
        setCreate(!create);
    }
    const handleCriteria = (e) => {
        const n = e.target.name;
        const v = e.target.value;
        setCriteria(values => ({ ...values, [n]: v }));
    }
    return (
        <div className="card shadow mb-3">
            <div className="card-header py-3">
                <div className="row">
                    <div className="col-sm-5 col-md-4 col-lg-3 col-xl-2 text-nowrap">
                        <div className="input-group d-inline-block w-auto">
                            <span className="input-group-text d-inline-block">Show</span>
                            <select onChange={handleCriteria} value={criteria.page_size} name={"page_size"} className="form-select d-inline w-auto">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <div className="input-group d-inline-block w-auto">
                            <span className="input-group-text d-inline-block">Topics</span>
                            <select value={criteria.topic_id ?? ""} onChange={handleCriteria} name="topic_id" className="form-select d-inline w-auto" >
                                <option value="" >-select topic-</option>
                                {topics.map((v, i) => <option key={i} value={v.id} >{v.title}</option>)}
                            </select>
                        </div>
                    </div>
                    <Hide state={!(typeof picker == 'undefined')}>
                        <div className="col-sm-7 col-md-8 col-lg-9 col-xl-10 text-end">
                            <button onClick={() => setCreate(!create)} className="btn btn-primary">New Question</button>
                        </div>
                    </Hide>
                </div>
            </div>
            <div className="card-body">
                <Hide state={create}>
                    <div className="card mb-3">
                        <div className="card-body">
                            <QCreate back={() => setCreate(!create)} create={() => createCallback()} />
                        </div>
                    </div>
                </Hide>
                {items.map((q, i) => <div key={i}>
                    <ManageQuestion question={q} picker={picker} />
                </div>)}
                <div className="row">
                    <div className="col-12 text-end">
                        <p>Showing {((criteria.page - 1) * criteria.page_size) + 1} to {((criteria.page - 1) * criteria.page_size) + items.length} of {criteria.count}</p>
                    </div>
                    <div className="col text-end">
                        <Pagination criteria={criteria} goto={setPage} />
                    </div>
                </div>
            </div>
        </div>);
}

export const ManageQuestion = ({ question, picker }) => {
    const [edit, setEdit] = useState(false);
    const [ques, setQues] = useState(question);
    useEffect(() => {
        setQues(question);
        setEdit(false);
    }, [question]);

    const update = (question) => {
        setQues(question);
        setEdit(!edit);
    }
    return <div className="card mb-3">
        <div className="card-body">
            <Hide state={!edit}>
                <QEdit question={ques} back={() => setEdit(!edit)} update={update} />
            </Hide>
            <Hide state={edit}>
                <Question question={ques} />
                <QuestionAnswers question={ques} />
                <Hide state={typeof picker == 'undefined'}>
                    <button onClick={() => picker(question)} className={`btn btn-secondary btn-sm float-end`} type="button">
                        <i className="fas fa-magic"></i>
                    </button>
                </Hide>
                <Hide state={!(typeof picker == 'undefined')}>
                    <button className="btn btn-danger btn-sm float-end" onClick={() => { }}>Delete</button>
                    <button className="btn btn-primary btn-sm float-end me-2" onClick={() => setEdit(!edit)}>Edit</button>
                </Hide>
            </Hide>
        </div>
    </div>;
}

export default GeneratedQuestions;