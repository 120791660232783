import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { Transforms } from "slate"
import { PopOverDirect } from "../../utils/popover"
import { getAncestor, getNode } from "../utils"

export const PropButton = ({ editor, show = false, setShow = () => { } }) => {
    return (
        <Button variant="outline-primary" onClick={() => setShow(!show)}>
            <i className="fas fa-ellipsis-v"></i>
        </Button>
    )
}

export const Properties = ({ editor, show = false, setShow = () => { } }) => {
    const [props, setProps] = useState({});
    useEffect(() => {
        const node = getNode(editor);
        const paragraph = getAncestor(editor, "paragraph");
        const table = getAncestor(editor, "table");
        const tableCell = getAncestor(editor, "table-cell");
        if (paragraph) {
            props.paragraph = { node: paragraph };
            Object.keys(paragraph[0])
                .filter(p => !['children', 'type'].includes(p))
                .forEach(p => props.paragraph.attr = { ...props.paragraph.attr, [p]: paragraph[0][p] });
        }
        if (table) {
            props.table = { node: table };
            Object.keys(table[0])
                .filter(p => !['children', 'type'].includes(p))
                .forEach(p => props.table.attr = { ...props.table.attr, [p]: table[0][p] });
        }
        if (tableCell) {
            props.tableCell = { node: tableCell };
            Object.keys(tableCell[0])
                .filter(p => !['children', 'type'].includes(p))
                .forEach(p => props.tableCell.attr = { ...props.tableCell.attr, [p]: tableCell[0][p] });
        }
        if (node[0]) {
            let type;
            switch (node[0].type) {
                case "image":
                case "question-loader":
                    type = node[0].type;
                    props[type] = { node: node };
                    break;
                default:
                    break;
            }
            if (type) {
                Object.keys(node[0])
                    .filter(p => !['children', 'type'].includes(p))
                    .forEach(p => props[type].attr = { ...props[type].attr, [p]: node[0][p] });
            }
        }
        setProps({ ...props });
        // eslint-disable-next-line
    }, []);
    const buildProps = (key, prop) => {
        return (<div key={key}>
            <h6 className="text-capitalize">{key}</h6>
            {Object.keys(prop.attr).map(attr => <div key={`${key}-${attr}`} className="input-group">
                <span className="input-group-text">{attr}</span>
                <input onChange={(e) => propChange(e, key)} value={prop.attr[attr] || ""} name={attr} type="text" className="form-control" />
            </div>)}
            <hr />
        </div>);
    }
    const propChange = (e, key) => {
        const v = e.target.value;
        const n = e.target.name;
        props[key].attr = { ...props[key].attr, [n]: v };
        setProps(props);
        Transforms.setNodes(editor, { [n]: v }, { at: props[key].node[1] });
    }

    return (
        <PopOverDirect title={'Properties'} show={show} close={() => setShow(false)} buttons={[]} >
            {Object.keys(props).map(key => buildProps(key, props[key]))}
        </PopOverDirect>
    )
}